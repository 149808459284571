
.experiences-details-wrap {
    @include justify-align(space-between, center);
    margin: 27px 0 0;
    @include flex-direction(column);
    @include tablet{
        margin: 25px;
        @include flex-direction(row);
    }
}
.experiences-packages-wrap{
    background-color: $bg-secondary;

    @include tablet{
    background-color: $bg-secondary;
    }
  
    .activity-slider{
        padding: 20px 0 40px;
        p,h1,h2,h3,h4,h5,h6,h4.species-name,p.species-desc{
            padding-top: 10px;
            color: $font-color-primary;
            text-align: center;
            @include tablet{
                color:$font-color-primary;
                // text-align: left;
            }
        }
        img{
            border-radius: $border-rectangle-large;
            height: 106px;
            @include tablet{
                height:210px;
                max-width:356px;
            }
        }
    }
}