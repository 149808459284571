@layer components{
    @keyframes dancingLoop {
     0%{
         transform: translateX(0);
     }
     50%{
         transform:translateX(-10px);
     }
     100%{
         transform: translateX(0);
     }
    }
    .dacing-arrow{
     display: inline-block;
    }
    .dance-loop{
     animation: dancingLoop 3s infinite;
    }
 }
