.ImageBanner {
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @include media-width-max(1125) {
        // height: 520px;
    }

    @include media-width-max(758) {
        // height: 341px;
    }

    &.withGradientTopBottom {
        // background-image: linear-gradient(180deg, rgba(2, 10, 29, 0) 0%, #020A1D 100%), url('your-image-url.jpg');
        background-size: cover;
        background-position: center;

        img {
            background-image: linear-gradient(180deg, rgba(2, 10, 29, 0) 0%, #020A1D 100%);
        }
    }
}