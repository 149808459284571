.blue-icon-card {
  display: inline-block;
  margin-right: 10px;
  @include tablet{

  }
  cursor: pointer;
  .blue-icon-card-content{
    @include justify-align-direction(center, center, column);
    word-break: break-word;
    width: 75px;
    @include tablet{
      width: 80px;
    }
  }

  .image {
    background-color: $bg-secondary;
    border-radius: 50px;
    width: 42px;
    height: 42px;
    margin: 0 auto;
    @include tablet {
      width: 80px;
      height: 80px;
    }
    @include justify-align(center, center);
    img {
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      padding: 4px;
      @include tablet {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
      }
      object-fit: contain;
    }
  }

  .number-count {
    font-size: 23.04px;
    letter-spacing: 1px;
    color: $font-color-yellow;
  }
  .species-name {
    font-size: 12px;
    line-height: 14px;
    display: inline-block;
    text-align: center;
    color: $font-color-dark;
    max-width: 100px;
    width: 100%;
    padding-top: 5px;
    white-space: normal;
    position: relative;
    //max-width: 42px;
    max-width: 77px;
    padding-bottom: 10px;

  }
  @include tablet {
    &.active {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: $font-color-primary;
        border-radius: 50px;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &.active {
    .species-name {
      color: $font-color-primary;
    }
  }
  &.noActive{
    &::before {
      display: none;
    }
    .species-name {
      color: $font-color-dark-onLight;
    }
  }
}
