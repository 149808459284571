.scanFishContainer {
    .heading {
        padding: 0px 40px;

        h1 {
            @include basic-text($font-Skia-Bold, 27.65px, $font-color-primary, 32px, center);
        }
    }
    .infoText{
        @include justify-align(center, center);
        margin: 10px;
        p{
            @include justify-align(center, center);
            height:22px;
            width:90px;
            border-radius: 4px;
          
            @include basic-text($font-Skia-Bold, 12px, $font-color-white, 14px, center);
            background-color: $bg-primary;
           
        }
    }

    .fishImage {
        @include justify-align(center, center);
        padding: 25px 0;
        border-bottom: $border-bottom-white;
        img {
            width: 220px;
            height: 220px;
            border-radius: 100%;
        }
    }
    .conservation-section{
        .MainTitleContent-wrap{
            h2{
                @include basic-text($font-Skia-Bold, 19.2px, $font-color-primary, 23px, center);
            }
        }
    }
    .detail {
        .description {
            @include basic-text($font-AvertaPE-Regular, 14px, $font-color-dark-onLight, 21px, center);
            padding: 40px 0;
        }
    }
    .localisationContainer {
        // @include justify-align-direction(center,center, column);
        .localisation {
            @include basic-text($font-Skia-Bold, 19.2px, $font-color-primary, 23px, center);
        }
        .LocationMap {
            padding: 25px 0;
            img {
                width: 100%;
                height: 230px;
                object-fit: cover;
            }
        }
    }
    .actionContainer {
        @include justify-align(center, center);

        button {
            width: 140px;
        }
    }

    .fishdetailContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 25px 0;
        border-bottom: $border-bottom-white;

        .item-list {
            display: flex;
            flex: 1;
            @include justify-align-direction(center, center, column);
            img {
                width: 26px;
                margin: 0 0 8px;
            }
            .detail {
                color: $font-color-dark;
                @include basic-text($font-AvertaPE-Regular, 14px, $font-color-dark-onLight, 21px, center);
            }
        }
    }
}