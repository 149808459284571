.MainTitleContent-wrap {
    h2 {
        font-family: $font-Skia-Bold;
        font-size: 23.04px;
        line-height: 27px;
        letter-spacing: 0px;
        //text-align: center;
        @include tablet {
            font-size: 33.18px;
            line-height: 39px;
        }
    }

    p {
        font-family: $font-AvertaPE-Regular;
        font-size: 14px;
        line-height: 21px;
        @include tablet {
            // text-align: left;
        }
    }

    &.themeDark {
        h1,h2,h3,h4,h5,h6 {
            color: $font-color-primary;
        }

        p {
            color: $font-color-light-onDark;
        }
    }

    &.themeLight {
        h1,h2,h3,h4,h5,h6 {
            color: $font-color-primary;
        }

        p {
            color: $font-color-dark-onLight;
        }
    }

    &.themeWhite {
        h1,h2,h3,h4,h5,h6 {
            color: $font-color-white;
        }

        p {
            color: $font-color-white;
        }
    }

 &.compactHeading {
    .heading {
        padding: 0 40px;
        @include tablet{
            padding: 0px;
        }
    }
 }
}