.TakePledge {
    display: flex;
    flex-direction: column;

    &.web-Pledge {
        margin: auto;
        padding: 0;

        .imgContainer {
            flex: 1;
        }

        .img {
            width: calc(100% + 30px);
            height: 100%;
            object-fit: cover;
            border-radius: 20px 0px 0px 20px;
        }

        .mainContainer {
            background-color: $bg-primary;
            padding: 50px 0;

            .container {
                @include justify-align(space-between, center);
                @include justify-align-direction(space-between, center, row-reverse);
            }

            .content-wraper {
                width: 75%
            }

            .pledgeSection {
                background-color: transparent;

                p {
                    font-size: 10px;
                    line-height: 14px;
                    text-align: center;
                    padding: 9px 0px;
                }

                .button-wrap.btn-backgrounded {
                    width: 160px;
                    padding: 0px;
                    text-transform: unset;
                    height: 44px;
                    margin: 0;

                    img {
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    &.mobile-Pledge {
        margin: 0px;
        //padding-bottom: 40px;

        .img {
            align-self: center;
            width: 100%;
            height: 180px;
            object-fit: cover;
            // border-radius: 20px 20px 0px 0px;
        }

        .mediaContainer {
            flex: 1;
            height: 160px;
            width: 100%;
            background: $bg-primary;

            video {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }

        .mainContainer {
            position: relative;
            background: $bg-primary;
            // padding-top: 85px;
        }

        .contentContainer {
            margin: 20px 20px 0px 20px;
        }

        .pledgeSection {
            //background-color: $bg-active;
            text-align: center;
            @include flex-align-direction(center, column);
            //@include position(absolute, right, 0, left, 0);
            top: -25px;
            border-radius: 12px;
            width: 90%;
            height: 90px;
            justify-content: center;

            p {
                font-family: $font-AvertaPE-Regular;
                font-size: 10px;
                line-height: 14px;
                color: $font-color-light-onDark;
                text-align: center;
                padding: 9px 0px;
            }

            .button-wrap.btn-backgrounded {
                width: 160px;
                padding: 0px;
                text-transform: unset;
                height: 44px;
                margin: 0;
                img {
                    margin-right: 5px;
                }
            }
        }
    }

    @include tablet {
        flex-direction: column;

        .mediaContainer {
            height: 460px;
            width: 100%;

            video {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
    }




}