.care-conservation-container {
    background: $bg-white;
    @include tablet{
        background: $bg-secondary;
    }
    .care-section-2 {
        background-color: $bg-secondary;
        margin: 0 20px;
        border-radius: $border-rectangle-large;

        @include tablet {
            background-color: $bg-white;
            margin: unset;
            border-radius: 0;
        }

        .activity-content-slider {
            margin-right: -20px;

            @include tablet {
                margin-right: unset;
            }
        }
    }

    .care-section-3 {
        padding-top: 50px;
        padding-bottom: 50px;
        @include flex();
        gap: 32px;
        @include flex-direction(column);

        @include tablet {
            gap: 64px;
            @include flex-direction(row);
        }

        &>:first-child>* {
            max-width: 641px;
        }
    }

    .care-section-4 {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 50px;
        max-height: unset;

        @include tablet {
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 100px;
            max-height: 443px;
            background-color: $bg-white;
        }

        .conservation-section {
            padding: 20px 20px 48px;
            background-color: $bg-dark;
            background-image: url(../../assets/images/careConservation/video-wave.svg);
            background-size: cover;
            background-position: center;

            @include tablet {
                @include flex-justify-direction(center, column);
                background-color: $bg-white;
                background-image: none
            }

            .MainTitleContent-wrap {
                h2 {
                    color: $font-color-white;

                    @include tablet {
                        color: $font-color-primary;
                    }
                }

                p {
                    color: $font-color-light-onWhite;

                    @include tablet {
                        color: $font-color-light-onWhite;
                    }
                }
            }

        }
    }
    .care-section-4-tablet{
        //display: grid;
        background: #fff;
        //grid-template-columns: 1fr;
        //grid-column-gap: 50px;
        max-height: unset;
        .layoutContainer {
            display: grid;
            grid-template-columns: 1fr;
            grid-column-gap: 50px;
            max-height: unset;
           
            @include tablet {
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 100px;
                max-height: 443px;
                background-color: transparent;
                .card.contentBottom{    
                    background: transparent;

                }
                .card.contentBottom .contentContainer {
                    margin: 0px 0px;
                }
            }

            .conservation-section {
                padding: 20px 20px 48px;
                background-color: $bg-dark;
                background-image: url(../../assets/images/careConservation/video-wave.svg);
                background-size: cover;
                background-position: center;

                @include tablet {
                    @include flex-justify-direction(center, column);
                    background-color: $bg-white;
                    background-image: none
                }

                .MainTitleContent-wrap {
                    h2 {
                        color: $font-color-white;

                        @include tablet {
                            color: $font-color-dark;
                        }
                    }

                    p {
                        color: $font-color-light-onWhite;

                        @include tablet {
                            color: $font-color-light-onWhite;
                        }
                    }
                }

            }
        }
        &.setBackground50{
            
        }
    }

    .care-section-5 {
        padding: 70px 0 70px;

        @include tablet {
            padding: 70px 0 100px;
        }

        .care-section-5-main {
            max-width: 281px;
            margin: 0 auto;

            @include tablet {
                max-width: 1014px;
            }

            .p-dark,
            .p-light {
                display: none;

                @include tablet {
                    display: block;
                }
            }
        }
    }

    .care-section-6 {
        padding: 20px 0 10px;
        margin: 0 20px 50px;
        border-radius: $border-rectangle-large;

        @include tablet {
            padding: 80px 0;
            margin: unset;
            border-radius: 0;
        }

        .care-section-6-wrap {
            @include justify-align-direction(center, center, column);
            gap: 20px;

            @include tablet {
                gap: 130px;
                @include flex-direction(row);
            }

            .association-images-wrap {
                @include justify-align(space-between, center);
                gap: 30px;

                @include tablet {
                    gap: 50px;
                }

                img {
                    max-height: 119px;

                    @include tablet {
                        max-height: 245px;
                    }
                }
            }

            .association-content-wrap {
                max-width: 475px;
            }

            .association-btn-wrap {
                @include tablet {
                    display: none;
                }
            }
        }
    }
}