.PageHeadingContent-wrap {
    .heading {
        font-family: $font-Skia-Bold;
        font-size: 58px;
        line-height: 58px;
        letter-spacing: 1px;
        text-align: left;
        @include media-width-max(1520) {
            font-size: 58px;
            line-height: 58px;
            text-align: left;
          }
          @include media-width-max(1125) {
            font-size: 58px;
            line-height: 58px;
            text-align: center;
          }
          @include media-width-max(758) {
            font-size: 23.04px;
            line-height: 27px;
            text-align: center;
            max-width: 263px;
            margin: 0 auto;
          }
    }
    p {
        font-family: $font-AvertaPE-Regular;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
          @include media-width-max(1125) {
            text-align: center;
          }
    }

    &.themeDark {
        h3 {
            color: $font-color-dark;
        }

        p {
            color: $font-color-light-onWhite;
        }
    }

    &.themeLight {
        h3 {
            color: $font-color-primary;
        }

        p {
            color: $font-color-dark;
        }
    }

}