// font
$font-Roboto-Italic:'Roboto-Italic',sans-serif;
$font-Roboto-Thin:'Roboto-Thin',sans-serif;
$font-Roboto-ThinItalic:'Roboto-ThinItalic',sans-serif;
$font-Roboto-Light:'Roboto-Light',sans-serif;
$font-AvertaPE-Regular:'AvertaPE-Regular',sans-serif;
$font-Roboto-Medium:'Roboto-Medium',sans-serif;
$font-Roboto--MediumItalic:'Roboto-MediumItalic',sans-serif;
$font-Roboto-Black:'Roboto-Black',sans-serif;
$font-Roboto-BlackItalic:'Roboto-BlackItalic',sans-serif;
$font-Skia-BoldItalic:'AvertaPE-BoldItalic',sans-serif;
$font-Skia-Bold:'AvertaPE-Bold',sans-serif;

$font-Skia: 'Skia',sans-serif;
$font-Skia-Bold: 'Skia-Bold',sans-serif;
$font-Skia-Regular: 'Skia-Regular',sans-serif;

// border params
$border-width-1:1px;
$border-width-2:2px;

//border color
$border-color-light:#0A7C8A;
$border-color-white:#ffffff;
$border-color-dark:#0A7C8A;
$border-color-primary:#0A7C8A;
$border-color-onWhite-opacity: #0A7C8A;

//border-radius
$border-round:50%;
$border-oval:30px;
$border-rectangle:12px;
$border-rectangle-large:20px;
$border-primary:22px;

/* Font  */


$font-color-white: #FFFFFF;
$font-color-dark: #020A1D;
$font-color-light-onWhite: #797F8D;
$font-color-light-onDark: #ffffff;
$font-color-dark-onLight: #020A1D;
$font-color-blue:#012169;

$font-color-primary:#0A7C8A;
$font-color-primary-hover: #08626d;

$font-color-yellow:#ffb600;
$font-color-error: #F82627;
$font-color-cyan: #5bc0de;
//link color 
$link-color:#2DCCD3;

// border
$blue-colored-border:$border-width-2 solid $border-color-light;
$white-colored-border:$border-width-2 solid $border-color-white;
$thick-dark-border:$border-width-2 solid $border-color-dark;
$light-dark-border:$border-width-1 solid $border-color-dark;
$thick-primary-border:$border-width-2 solid $border-color-primary;
$border-bottom-white:1px solid rgb(255 255 255 / 20%);
$border-bottom-primary:0.5px solid $border-color-primary;
$border-white: $border-width-1 solid $border-color-onWhite-opacity;
$border--full-white: $border-width-2 solid $font-color-white;

// Line Color
$line-color: #797F8D;

//background
$bg-primary:#0A7C8A;
$bg-primary-hover: #08626d;

$bg-secondary:#EAF9FA;
// $bg-secondary-hover: #EAF9FA;

$bg-yellow:#FFB600;
$bg-yellow-hover:#DA8824;
$bg-dark:#020A1D;
$bg-white:#FFFFFF;
$bg-grey:#EEF1F6;
$bg-transparent:transparent;
$bg-active: #012169;
$icon-bg:#0B1630;

/* Header */
$header-color: #184f90;
$header-color-2: #0f2660;

/* Section Padding */
$padding: 15px 16px;

/* Box Shadow */
$box-shadow-1: 0 3px 6px #efefef;

/* Scroll */
$scroll-background: transparent;

