.fishDetection-section{
    background: rgba(45, 204, 211, 0.2);
    backdrop-filter: blur(5px);
    width: 100%;
    height: 70px;
    @include justify-align(center, center);
    margin: 20px 0;
    span{
        color: $font-color-white;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        padding-left: 10px;
    }
}