.ExpertList {
    background-color: $bg-white;
   @include tablet{
    padding: 30px 0;
   }
    .expertHead {
        padding: 30px 0;
    }

    .gridContainer {
        background:$bg-primary;
        display: grid;
        grid-gap: 30px;
        //padding: 40px 0;
        padding: 10px 0 30px 0;
        grid-template-columns: repeat(2, 1fr);
        padding: 20px;
        border-radius: 20px;
        @include tablet{
            grid-template-columns: repeat(3, 1fr);
            padding: 40px;
            border-radius: 20px;
        }
    }

    .gridItem {
        text-align: center;

        .image {
            height: 126px;
            width: 124px;
            border-radius: 100%;
        }

        .expertName {
            @include basic-text($font-Skia-Bold, 14px, $font-color-white, 21px, center);
            padding: 5px 0px;
        }

        .expertPosition {
            @include basic-text($font-AvertaPE-Regular, 12px, $font-color-white, 14px, center);
            max-width: 180px;

        }

        @include tablet {
            @include justify-align-direction(flex-start, center, row);
            gap:15px;
            .expertName {
                @include basic-text($font-Skia-Bold, 22px, $font-color-white, 27px, center);
                padding: 5px 0px;
            }

            .expertPosition {
                @include basic-text($font-AvertaPE-Regular, 12px, $font-color-white, 14px, center);
                padding-left: 0px;
            }
        }
    }

    .center {
        grid-column-start: 1;
        grid-column-end: 3;
        @include justify-align-direction(center, center, column);

        .expertPosition {
            margin: 0 auto;
            max-width: 160px;
        }
    }

}