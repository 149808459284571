.categoryItems {
  padding: 30px 0 10px 0;
  border-bottom: 1px solid $border-color-onWhite-opacity;
}
.animals-banner {
  .banner-Image {
    .box-1 {
      max-width: 502px;
      width: 100%;
    }
  }
}
@media (max-width: 768px) {
  .animals-banner {
    .ImageBanner{
        position: relative;
    }
    .ImageBanner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgb(2, 10, 29,1) 0%, rgba(1, 33, 105, 0) 10%);
    z-index: 1;
}
.ImageBanner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(360deg, rgb(2, 10, 29,1) 0%, rgba(1, 33, 105, 0) 10%);
  z-index: 1;
}
    .MainTitleContent-wrap{
        position: absolute;
        top: 150px;
        width: 100%;
        @include justify-align-direction(center, center, column);
        .heading{
            max-width: 263px;
            width: 100%;
        }
        p{
            max-width: 307px;
            width: 100%;
        }
    }
  }
}
