.discover-banner-1 {
  position: relative;
  .banner1 {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 230px;
  }
  .bg-overlay {
    content: "";
    //position: absolute;
    width: 100%;
    height: 100%;
    // top: 0;
    // left: 0;
    // background: linear-gradient(
    //   0deg,
    //   rgba(2, 10, 29, 0.4),
    //   rgba(2, 10, 29, 0.4)
    // );
    z-index: 1;
    transform: rotate(-180deg);
  }
  .banner-content {
    position: relative;
    z-index: 3;
    padding: 30px 0;
    background-color: $bg-secondary;
    
    .button-wrap.btn-white-outlined {
      max-width: 200px;
      padding: 0px;
      margin: 0 auto;
      color: $font-color-primary !important;
    }
    .button-wrap.btn-backgrounded {
      max-width: 200px;
      padding: 0px;
      margin: 0 auto;
      color: $font-color-white !important;
    }
    .MainTitleContent-wrap {
     .detailConteiner {
        max-width: 290px;
        width: 100%;
        color: $font-color-dark-onLight;
        margin: 0 auto;
        padding: 10px 0 20px 0;
        font-family: $font-AvertaPE-Regular;
            font-size: 14px;
            line-height: 21px;
      }
      .heading{
        //color: $font-color-dark;
        font-size: 23.04px;
        line-height: 27px;
        max-width: 250px;
        width: 100%;
        margin: 0 auto;
      }
    }
  }

  @include tablet {
    .banner1 {
      min-height: 620px;
    }
    .banner-content {
      position: absolute;
      z-index: 3;
      top: 30%;
      transform: translateX(10%);
      background-color: transparent !important;
      .button-wrap.btn-white-outlined {
        margin: unset;
        color: $font-color-white !important;
      }
      
      .MainTitleContent-wrap {
        text-align: left !important;
        .detailConteiner {
           max-width: 600px;
           width: 100%;
           color:$font-color-light-onDark;
           margin: 0 auto;
           padding: 10px 0 20px 0;
           font-family: $font-AvertaPE-Regular;
            font-size: 14px;
            line-height: 21px;
         }
         .heading{
           color: $font-color-white;
           font-size: 33.18px;
           line-height: 39px;
           max-width: unset;
           width: 100%;
           margin: unset;
         }
       }
    }
  }
}

.discover-banner-2 {
  position: relative;
  .banner2 {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    width: 100%;
    height: 230px;
  
    background-size: cover;
    @include tablet{
      background-attachment: fixed;
    }
    // background-position: top;
  }
  .bg-overlay {
    content: "";
   // position: absolute;
    width: 100%;
    height: 100%;
    // top: 0;
    // right: 0;
    // background: linear-gradient(
    //   0deg,
    //   rgba(2, 10, 29, 0.4),
    //   rgba(2, 10, 29, 0.4)
    // );
    // z-index: 1;
    // transform: rotate(-180deg);
  }

  .banner-content {
    position: relative;
    z-index: 3;
    padding: 30px 0;

    .button-wrap.btn-backgrounded {
      max-width: 200px;
      padding: 0px;
      margin: 0 auto;
      color: $font-color-white !important;
    }
    .MainTitleContent-wrap {
      text-align: center;
      .detailConteiner {
        padding-bottom: 20px;
        max-width: 290px;
        width: 100%;
        margin: 0 auto;
        font-family: $font-AvertaPE-Regular;
            font-size: 14px;
            line-height: 21px;
          color:$bg-dark
      }
    }
    
    .speices-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 auto;
      max-width: 350px;
      width: 100%;
      padding-bottom: 30px;
      .number-card {
        margin: 10px 4px;
        width: unset;
        flex: 1;
        @include tablet{
          flex: unset;
        }
        .dark-bg {
          width: 80px;
          height: 80px;
          border: 1px solid $border-color-dark;
          @include tablet{
            border: 0px;
          }
        }
        .light-bg {
          border: 1px solid $border-color-primary;
          width: 80px;
          height: 80px;
          @include tablet{
            border: 0px;
          }
        }
        .species-name{
        width: 98%;
        padding-top: 8px;
        color:$bg-primary;
        @include tablet{
          flex: unset;
          color:$bg-white;
        }
        }
      }
    }
  }
  .button-wrap.btn-outlined {
    max-width: 200px;
    padding: 0px;
    margin: 0 auto;
  }

  .button-wrap.btn-white-outlined {
    max-width: 200px;
    padding: 0px;
    margin: 0 auto;
  }

  .MainTitleContent-wrap {
    .detailConteiner {
      max-width: 600px;
      width: 100%;
      font-family: $font-AvertaPE-Regular;
            font-size: 14px;
            line-height: 21px;
    }
  }
  @include tablet {
    .banner2 {
      min-height: 620px;
    }
    .banner-content {
      position: absolute;
      z-index: 3;
      right: 0;
      top: 30px;
      transform: translateX(-13%);
      padding: 0px;
      .MainTitleContent-wrap {
        .detailConteiner {
          color:$bg-white;
          padding-bottom: 20px;
          max-width: 608px;
          width: 100%;
          margin: unset;
          text-align: left;
          font-family: $font-AvertaPE-Regular;
            font-size: 14px;
            line-height: 21px;
        }
        h2 {
          text-align: left;
          color: $bg-white;
        }
      }
      .speices-list {
        justify-content: left;
        align-items: left;
        max-width: 550px;
        width: 100%;
        margin: unset;
        // @include justify-align(left, left);
        .number-count {
          background-color: $bg-primary;
          font-size: 33.18px;
          width: 80px;
          height: 80px;
          letter-spacing: 1px;
          border-radius: 12px;
          color: $font-color-white;
          @include justify-align(center, center);
        }
        .species-name{
          max-width: 60px;
        }
      }
    }
    .button-wrap.btn-outlined {
      margin: unset;
    }
    .button-wrap.btn-white-outlined {
      margin: unset;
    }
  }
}
.discover-section3 {
  width: 100%;
  @include justify-align-direction(center, center, column);
  .wrapper-section {
    padding: 20px;
    background-color: $bg-secondary;
    .left-content {
      background-color: $bg-white;
      width: 100%;
      padding: 50px 20px 33px;
      border-radius: 12px;
      margin-bottom: 20px;
      @include justify-align-direction(center, center, column);
      .MainTitleContent-wrap {
        text-align: center;
        .heading {
          max-width: 411px;
          width: 100%;

          font-size: 23.04px !important;
          line-height: 27px !important;
        }
        .detailConteiner {
          max-width: 475px;
          width: 100%;
          padding: 10px 0;
          font-family: $font-AvertaPE-Regular;
            font-size: 14px;
            line-height: 21px;
            color: #797F8D; 
        }
      }
    }
  }
  .right-content {
    width: 100%;
    background-color: $bg-white ; 
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;


    .ExpertList {
      padding: 33px 0;
      .expertHead {
        padding-left: 10px;
        .MainTitleContent-wrap {
          .detailConteiner {
            max-width: 475px;
            width: 100%;
            padding-top: 20px;
            font-family: $font-AvertaPE-Regular;
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
      // .gridContainer {
      //   grid-template-columns: repeat(2, 180px);
      //   .gridItem {
      //     flex-direction: column;
      //     .expertName {
      //       font-size: 14px;
      //       line-height: 21px;
      //       text-align: center;
      //     }
      //   }
      // }

      .gridContainer {
        background:$bg-primary;
        display: grid;
        grid-gap: 30px;
        //padding: 40px 0;
        padding: 10px 0 30px 0;
        grid-template-columns: repeat(2, 1fr);
        padding: 20px;
        border-radius: 20px;
      
    }

    }
  }
  @include tablet {
    background-color: $bg-white;
    @include justify-align-direction(space-between, flex-start, row);
    .wrapper-section {
      width: 50%;
      padding: 0px;
      background-color: $bg-white;
      .left-content {
        border-radius: 0px;
        margin-bottom: 0px;
        .MainTitleContent-wrap {
          text-align: left;
          position: relative;
          left: 50px;
          .heading {
            font-size: 33.18px !important;
            line-height: 39px !important;
          }
          .detailConteiner{
            text-align: left;
            font-family: $font-AvertaPE-Regular;
            font-size: 14px;
            line-height: 21px;
            color: $font-color-dark-onLight; 
            padding-right: 20px
          }
        }
        .conservation-section{
          .MainTitleContent-wrap{
            position: relative;
            left: 15px;
          }
        }
      }
    }
    .right-content {
      width: 50%;
      background-color: $bg-white ;

      .ExpertList {
        .expertHead {
          padding-left: 100px;
          h2 {
            text-align: left;
          }
          p{
            text-align: left;
          }
        }
        .gridContainer {
          grid-template-columns: repeat(3, 200px);
          position: relative;
          left: 100px;
          .gridItem {
            flex-direction: column;
          }
        }
      }
    }
  }
}
