.attraction-container {
   padding-top:40px;
    .gridWithBgImage {
        @include list-view-sm4;
        margin:22px 8px 50px 8px;
    }
    .gridItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        &:hover{
          .Title-sub-blue{
              color: $font-color-primary-hover;
          }
          .image{
            transform:  scale(1.25) ;
            -webkit-transform:  scale(1.25) ;
          }
        }
        .gridImage{
          margin-bottom: 10px;
          border-radius: 30px;
          overflow: hidden;
        }
        .image {
            width: 100%;
            border-radius: 30px;
            cursor: pointer;
            @include object-fit(cover);
           
            height: 480px;
            @include media-width-max(1520) {
                height: 480px;
              }
              @include media-width-max(1125) {
                height: 130px;
              }
              @include media-width-max(758) {
                height: 130px;
                width: 130px;
              }
              transition: all .35s ease-in;
              -webkit-transition: all .35s ease-in;
              // &:hover{
              //   transform:  scale(1.25) ;
              //   -webkit-transform:  scale(1.25) ;
              // }
        }
        
    }
    
}