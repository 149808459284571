.grid-item-icon-container{

    
    .grid-item-icon--item {
        display: grid;
        row-gap: 20px;
        column-gap: 25px;
        @include tablet{
            row-gap: 20px;
        }
       &.item-grid--6 {
         grid-template-columns: repeat(3, 1fr);
            @include tablet {
                grid-template-columns: repeat(6, 1fr);
            }
        }
        &.item-grid--5 {
            grid-template-columns: repeat(3, 1fr);
            @include tablet {
                grid-template-columns: repeat(5, 1fr);
            }
        }
        &.item-grid--4 {
            grid-template-columns: repeat(3, 1fr);
            @include tablet {
                grid-template-columns: repeat(4, 1fr);
            }
        }
        &.item-grid--3 {
            grid-template-columns: repeat(3, 1fr);
            @include tablet {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}
  