.Title-sub-blue{
    font-family: $font-Skia-Bold;
    font-size: 16px;
    line-height: 22px;
    //letter-spacing: 1px;
    color: $font-color-primary;
   // text-align: left;
    @include tablet{
        font-size: 20.04px;
        line-height: 27px;
    }

    &.sm{
        font-size: 14px;
        line-height: 21px;
        
        @include tablet{
            font-size: 14px;
            line-height: 21px;
        }
    }
    &.lg{
        font-size: 20.04px;
        line-height: 27px;
        
        @include tablet{
            font-size: 20.04px;
            line-height: 27px;
        }
    }
}




