.p-dark{
    font-family: $font-AvertaPE-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: $font-color-dark;
    margin-bottom: 20px;
}

.p-white{
    font-family: $font-AvertaPE-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: $font-color-white;
    margin-bottom: 20px;
    p{
        color:#ffffff
    }
}