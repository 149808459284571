.accordion {
    width: 100%;
    max-width: 470px;
    padding-bottom: 10px;
    .panel {
      background-color: $bg-transparent;
  
      &__label {
        position: relative;
        display: block;
        width: 100%;
        background: none;
        border: none;
        text-align: left;
        padding-bottom: 0px;

        padding-top: 18px;

        font-weight: 500;
        font-size: 17px;
        font-family: inherit;
        transition: color 0.2s linear;
        cursor: pointer;
        @include justify-align(space-between, center);
        
        gap: 20px;
        &:focus {
          outline: none;
        }
        .panel-content-wrap{
          @include justify-align(center, center);
          gap: 20px;

        .panel-img-wrap{
          width: 80px;
          height: 80px;
          img{
            width: 100%;
            height: 100%;
            border-radius: $border-round;
            object-fit: cover;
          }
      }
    }
  
        // &:before {
        //   content: url(../../assets/icons/btn-acc-plus.svg);
        //   position: absolute;
        //   right: 0;
        //   top: auto;
        //   bottom: auto;
        //   padding: 0;
        //   margin: 0;
        //   height: 32px;
        //   width: 32px;
        // }
  
        &:before {
          transition: transform 0.35s all ease-in;
        }
      }
  
      &[aria-expanded='true'] {
        .panel__content {
          opacity: 1;
        }
  
        .panel__label {
          color: #957029;
  
          // &:before {
          // content: url(../../assets/icons/btn-acc-minus.svg); 
          // }
        }
      }
  
      &__inner {
        overflow: hidden;
        will-change: height;
        transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
      }
  
      &__content {
        margin: 5px 25px 25px;
        font-size: 14px;
        color: #756658;
        opacity: 0;
        transition: opacity 0.3s linear 0.18s;
      }
  
      &:not(:last-child) {
        margin-bottom: 3px;
      }
    }
  }