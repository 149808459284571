.grid-carousel-container{
    .grid-carousel-list{
     .grid-carousel-list-item{
        @include justify-align(center, center);
        @include flex-direction(column);
        gap: 10px;
        margin-bottom: 20px;
        text-align: center;
        p{
            max-width: 120px;
        }
        .fish-scan-wrap{
            width: 120px;
            height: 120px;
            margin: 10px auto;
            border-radius: $border-round;
            background-color:  $bg-secondary;
            cursor: pointer;
            @include justify-align(center, center);
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: $border-round;
                    padding: 10px;
                }
        &.active{
            img{
                padding: 0;
            }
        }
             
        }
       
     }
    }  
    .slick-dots li {
        width: 10px;
        margin: 0 2px;
        button:before {
        color: $font-color-white;
        opacity: 0.2;
        font-size: 6px;
        width: 10px;
        }
        &.slick-active button:before {
            opacity: .75;
            color: $font-color-primary;
        }
    }
}