.opening-hours-wrapper{
    padding: 2.1875rem 1.25rem;
    margin: auto;
    .opening-hours-container{
        border-radius: 20px;
        box-shadow: 0 15px 30px 0 rgba(0,0,0,.08);
        background-color: #fff;
        padding: 3.125rem 3.75rem;
        border: .0625rem solid rgba(10,124,138,.3);
        text-align: center;

        h2{
            font-size: 2rem;
            line-height: 2.375rem;
            margin-bottom: 35px;
            color: #0a7c8a;
            letter-spacing: 0;
        }

        .items{
            max-width: 1200px;
            padding: 0 .625rem;
            margin: 0 auto 1.25rem;
            display: grid;
            justify-content: normal;
            flex-wrap: nowrap;
            gap: 30px 90px;
            text-align: center;

            .item{
                position: relative;
                padding-right: .9375rem;
                padding-left: .9375rem;

                .icon img{
                    width: 28px;
                    height: 28px;
                }

                .timing{
                    font-weight: 400;
                    color: #0a7c8a;
                    font-size: .9375rem;
                    line-height: 1.3125rem;
                }

                .item-title{
                    font-weight: 400;
                    font-size: 22px;
                    color: #0a7c8a;
                    line-height: 1.4;
                }

                .open-status{
                    // font-family: Skia_Regular;
                    font-weight: 400;
                    color: #282828;
                    font-size: .9375rem;
                    letter-spacing: normal;
                    line-height: 1.3125rem;
                }
            }

            .item:last-child {
                border: none;
            }
            
        }

        .opening-hours-bottom{
            margin-bottom: 0;
            display: block;
            // font-family: Averta;
            font-weight: 400;
            margin-top: 50px;
            color: #282828;
            font-size: 15px;
            line-height: 21px;

            p, a{
                color: #282828;
                font-size: 15px;
                line-height: 21px;
                font-weight: 400;
            }

            a{
                text-decoration: underline;
            }
        }
    }
}


@media (min-width: 768px) {
    .opening-hours-wrapper{
        padding-top: 3.75rem;
        padding-bottom: 3.75rem;
        padding: 2.5rem 1.875rem;
        .opening-hours-container{
            .items{
                // grid-template-columns: calc(33% - 30px) calc(33% - 30px) auto;
                margin-bottom: 1.875rem;
                padding: 0 1.875rem;
            }
        }
    }

}

@media (min-width: 992px){
    .opening-hours-wrapper{
        padding: 3.75rem 7.5rem;
        .opening-hours-container{

            .items{

                .item{
                    
                }

                
            }
        }
    }
}

@media (min-width: 1280px){
    .opening-hours-wrapper{
        .opening-hours-container{
            .items{
                grid-template-columns: calc(33% - 30px) calc(33% - 30px) auto;


                .item:nth-child(3n+2):before {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: -1.875rem;
                    right: -1.875rem;
                    height: 100%;
                    border-left: .0625rem solid #e5e7ec;
                    border-right: .0625rem solid #e5e7ec;
                }
            }
        }
    }
}


@media (max-width: 1279px) {
    .opening-hours-wrapper{
        .opening-hours-container{
            padding: 2.1875rem .875rem;

            .items{

                .item{
                    padding-bottom: 30px;
                    border: none;
                    border-bottom: 1px solid #e4e7eb;
                }

                
            }
        }
    }
}