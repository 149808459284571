.accordionTitleContent-item{
    border-bottom:$border-bottom-primary;
    transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1); /* Add any desired transition properties */
&.active {
    height: auto; /* Set the desired height for the active state */
  }
    .accordion-item-title{
        @include justify-align(space-between,center);
        width: 100%;
        padding: 20px 0;
        cursor: pointer;

        h5{
            color: $font-color-primary;
            width: calc(100% - 96px);
        }
        .accordionTitlecontent-icon-wrap{
            width: 32px;
            height: 32px;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    .accordionTitlecontent-content{
        height: 0; /* Set initial height to 0 */
        overflow: hidden; /* Hide content when not active */
        transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1); /* Add any desired transition properties */
        &.active {
            height: auto; /* Allow content to expand to its full height when active */
          }
        p{
          color:$font-color-dark;
          padding-bottom: 20px;
        }

    }
}


//btn animation

  .circle-plus {
    .circle {
        position: relative;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        .horizontal {
            position: absolute;
            background-color: $bg-white;
            width: 14px;
            height: 2px;
            left: 50%;
            margin-left: -7px;
            top: 50%;
            margin-top: -1.0px;
        }
        .vertical {
            position: absolute;
            background-color: $bg-white;
            width: 2px;
            height: 14px;
            left: 50%;
            margin-left: -1.0px;
            top: 50%;
            margin-top: -7px;
        }
    }
    &.opened{
        .circle {
            border: $blue-colored-border;
            background-color: $bg-transparent;
          
              .vertical {
                transition: all 0.5s ease-in-out;
                transform: rotate(90deg);
                background-color: $bg-primary;
              }
              .horizontal {
                transition: all 0.5s ease-in-out;
                transform: rotate(90deg);
                opacity: 0;
              }
        }

    }
    &.closed{
        .circle {
            border: $border--full-white;
            background-color: $border-color-light;
              .vertical {
                transition: all 0.5s ease-in-out;
                transform: rotate(-90deg);
              }
              .horizontal {
                transition: all 0.5s ease-in-out;
                transform: rotate(-90deg);
                opacity: 1;
              }
        }
    }
}

  
  