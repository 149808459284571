
.all-chambers-list{
    @include list-view-small();
    padding: 10px 0 40px;

    grid-column-gap:24px;
    grid-row-gap:20px;
    @include tablet{
    @include list-view();
        padding: 30px 0 80px;
    }
}