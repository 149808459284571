.animals-chambers-wrap{
    @include list-view-small();
    row-gap: 40px;
    padding: 30px 0 50px;
    @include tablet {
    padding: 50px  0 70px;
    border-top: $border-white;

    }
    .animal-chambers-content-left{
        text-align: center;
        @include tablet {
            text-align: left;
        }
        .categories-extra-list{
            @include flex-justify(center);
            gap:0px;
            @include tablet{
            gap:20px;
            @include flex-justify(flex-start);
            }
        }
    }
    .animal-chambers-content-right{
        .MainTitleContent-wrap.themeDark h5{
            color: $font-color-dark-onLight;
        }
        div{
            margin: 0 auto;
            @include tablet {
                margin: unset;
            }
        }
    }
}