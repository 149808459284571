.card-slider {
  .cardBody {
    padding-right: 10px;
    @include tablet{
      padding: 0 10px;
    }
    .cardImg {
      transition: all .35s ease-in;
      -webkit-transition: all .35s ease-in;
      &:hover{
        transform:  scale(1.1) ;
        -webkit-transform:  scale(1.1) ;
      }
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 200px;
      @include tablet{
        height: 260px;
      }
    }
    .species-name {
      font-size: 14px;
      line-height: 21px;
      color: $font-color-primary;
      padding: 8px 0 0;
    }
    .species-desc{
      margin-top: 10px;
      @include tablet{
        margin-top: 20px;
      }
        max-width: 346px;
        width: 100%;
        color: $font-color-dark;
    }
    .cardBody-content{
      p,h4{
        transition: all .35s ease-in;
        -webkit-transition: all .35s ease-in;
        &:hover{
          color: $font-color-primary;
        }
      }
    }
    .cardBody-Imgcontent{
      border-radius: $border-rectangle-large;
      overflow: hidden;
    }
   

  }

&.slideSize80{
  .cardBody-Imgcontent  {
    height:80px;
    width:80px;
    border-radius: $border-round;
    margin: 0px auto;

    img{
      height:100%;
      width:100%;
    }
  }
}
&.slideSize110{
  .cardBody-Imgcontent  {
    height:110px;
    width:110px;
    margin: 0px auto;

    img{
      height:100%;
      width:100%;
    }
  }
  .slick-slide {
    width:120px !important;
  }
}
&.roundSlide{
  .cardBody {
    img{
      border-radius: 100%;
    }
  }
}
}