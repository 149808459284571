@media (min-width: 768px){
    .sticky-footer-icons {
        display: none;
      }
}
@media (max-width: 768px) {
    .sticky-footer-icons {
      display: flex;
      text-align: center;
      justify-content: space-between;
      align-items: center;
      z-index: 9;
      background-color: $bg-white;
      width: 100%;
      position: fixed;
      padding:5px 10px 16px 10px;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 0px;
      gap: 5px;
      border-top-left-radius: 10px;
      border-top-right-radius: 20px;
      &:before {
        content: "";
        position: absolute;
        top: -8px;
        left: 10px;
        right: 30px;
        height: 10px;
        background: $bg-primary;
        box-shadow: 0px -4px 20px rgba(5, 13, 32, 0.2);
        border-top-right-radius: 100%;
        border-top-left-radius: 7px;
      }
    
      .footer-icon{
        height: 40px;
        object-fit: contain;
        margin: 5px 0;
        border-radius: 18px;
        width: 55px;
        // padding: 8px 9px;
        @include justify-align(center, center);
        transition: 0.3s ease-in-out;
        // &:nth-child(3):nth-last-child(1) {
        //   padding: 0px;
        //   width: unset;
        // }
      }
      .Test {
        fill: #000;
      }
      .mb-footer-logo {
        width: 55px;
        color: #000;
        // padding: 3px;
        cursor: pointer;
        @include justify-align-direction(center, center, column);
        transition: 0.3s ease-in-out;
      }
  
      .stickyfooter-items {
        padding: 0 1rem;
        @include justify-align-direction(center, center, column);
      }
      .mb-footer-logo.active .footer-icon {
        background-color: $bg-secondary;
        color: $font-color-primary;
        width: 55px;
      }
      .mb-footer-logo.active:nth-child(5) .footer-icon svg path {
        fill: $font-color-primary;
      }
      .mb-footer-logo.active:nth-child(4) .footer-icon svg path{
        fill: $font-color-primary;
      }
      .mb-footer-logo.active .footerIconText {
        color: $bg-primary;
        font-weight: 800;
      }
  
      .footer-icon.active rect {
        fill: $font-color-white;
      }
  
      .footerIconText {
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 8px;
        text-align: center;
        color: $font-color-dark;
        width: 100%;
      }
  

    }
  }

