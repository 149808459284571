.page-not-found-container{
    @include justify-align(center,center);
    min-height: 50vh;
    flex-direction: column;
    //background: $bg-white;
    gap: 20px;
    padding: 30px 20px;
    .PageHeadingContent-wrap{
    .heading{
        font-size: 40px;
    }
}
}