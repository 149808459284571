.atlantis-container{
header{
    height: 0;
}
.main-body-content{
    //min-height:calc(100vh + 200px);
    padding-top: 110px;
    z-index: 1;
    position: relative;
    background-color: $bg-white;
    @include tablet{
        padding-top: 140px;
         margin-bottom: 690px;
    }
}
}
.headers {
    position: sticky;
    top: 0px;
    transition-property: all; 
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.5s;
    &.scroll-up {
        position: fixed;
        top: 0;
      }
    &.scroll-down {
     top: -140px;
    }
    &.bg-color-for-inner-pages{

      background-color: $bg-white;
      @include tablet {
        background-color:$bg-white;
      }
      }
      &.bg-primary{

        background-color: $bg-primary;
        @include tablet {
          background-color:$bg-primary;
        }
        }

  }
  .on-load, .scroll-up{
  &.transparentBg{
    background-color: transparent;
  }
}