.social-login-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;

  .video-container {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;

    video {
      height: 100%;
      object-fit: cover;
    }
  }

  .gradient-box {
    background: linear-gradient(0deg, rgba(2, 10, 29, 0.3) 0%, rgba(2, 10, 29, 0.3) 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
  }

  .socialLogin-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    @include justify-align-direction(center, center, column);

    .main-text {
      font-size: 22px;
      padding-bottom: 1rem;
    }

    .top-section {
      @include justify-align-direction(space-between, center, column);

      .social-btn {
        // margin: 5px auto;
      }

      .logo {
        padding-bottom: 60px;
      }

      .title {
        font-weight: 700;
        font-size: 19.2px;
        line-height: 23px;
        letter-spacing: 1px;
        color: $font-color-light-onDark;
        padding-bottom: 36px;
      }

      .description {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: $font-color-light-onDark;
        padding-top: 20px;

        .highlight {
          color: $font-color-light-onDark;
          text-decoration: underline;
          font-weight: 700;
          text-indent: 6px;
        }
      }

      .social-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
        border: 2px solid $border-color-light;
        border-radius: 30px;
        padding: 5px 30px;
        height: 50px;
        position: relative;

        img {
          position: absolute;
          left: 20px;
        }

        .facebook-btn {
          letter-spacing: 0.01px;
          text-align: center;
          color: $font-color-white;
          padding: 0;
          border: none;
          background-color: transparent;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 21px;
        }

        .google-btn {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 21px;
          letter-spacing: 0.01px;
          text-align: center;
          color: $font-color-white;
          border: none;
          background-color: transparent;
        }
      }

      .social-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      .top-section-1 {
        text-align: center;
        width: 100%;
      }

    }

    .middle-section {
      @include justify-align(space-between, center);
      padding: 90px 0 32px 0;
      gap: 10px;

      .image {
        width: 62px;
        height: 62px;
        border-radius: 50px;
        background-color: $bg-primary;
        position: relative;

        &::after {
          position: absolute;
          content: "";
          width: 30px;
          height: 30px;
          background-color: $bg-primary;
          transform: rotate(45deg);
          top: 16px;
          right: -7px;
        }

        img {
          width: 100%;
          height: 100%;
          padding: 10px;
          position: relative;
          z-index: 1;
        }
      }

      span {
        font-size: 14px;
        line-height: 21px;
        color: $font-color-light-onDark;
        max-width: 224px;
        width: 100%;
        // padding-left: 25px;
      }

      // @media (min-width: 300px) and (max-width: 667px) {
      //   padding-top: 2rem;
      // }
    }

    .bottom-section {
      .bottom-btn btn {
        margin: 1rem auto;
      }

      ::placeholder {
        color: #fff;
      }

      .email-input {
        width: 100%;
        font-size: 14px;
        // line-height: 16;
        text-align: center;
        color: #fff;
        backdrop-filter: blur(5px);
        border: 2px solid transparent ;
        border-radius: 30px;
        background: rgba(204, 209, 219, 0.15);
        height: 50px;
        text-decoration: none;
        padding: 10px 35px;

        &:focus {
          border: 2px solid #2DCCD3;
        }
      }


      .submit-btn {
        margin-top: 15px;
        border: 2px solid $border-color-light;
        background: transparent;
        border-radius: 30px;
        padding: 10px 30px;
        max-width: 352px;
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        color: $font-color-light-onDark;
      }

      .bottom-two-section {
        margin-top: 83px;
        @include justify-align(space-between, center);

        span {
          font-size: 14px;
          line-height: 21px;
          font-weight: 700;
          color: $font-color-white;
        }
      }
    }

    .social-btn img {
      // padding: 0 1rem;
    }

    // @include tablet {
    //   display: none;
    // }
  }
}

.login-social-contents {
  height: calc(100vh - 50px);
  overflow-y: scroll;
  width: 100vw;
  padding: 50px 35px 78px;
}

.social-logo {
  padding-top: 50px;
  padding-bottom: 15px;
}

.login-social-btn {
  display: flex;
  flex-direction: column;
  gap: 10px;
}