.content-grid-wrap{
    @include justify-align(flex-start, flex-start);
    gap: 23px;

    img{
        width: 80px;
        height: 80px;
        border-radius: $border-round;
        object-fit: cover;
        transition: all .35s ease-in;
        -webkit-transition: all .35s ease-in;
        @include tablet{
            width: 261px;
            height: 261px;
        }
    }
    .content-grid-body{
        height: 100%;
        @include justify-align-direction(space-between, flex-start, column );
        gap: 5px;
        .content-details-wrap{
            p{
            font-size: 14px;
            line-height: 21px;
            }
        }
    }
    &.activeCardContent{
        &:hover {
            img{
            transform: scale(1.1);
            -webkit-transform: scale(1.1);
           
            }
        }
    }
}