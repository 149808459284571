.modalContainer{
    overflow-y: scroll;
    height: 100%;
}
.scanResultContainer {
    padding: 5% 5% 0;
    // @include justify-align(center, center);
    @include justify-align-direction(center, center, column);
    .actionContainer{
        width:220px;
    }
    .message {
        @include basic-text($font-Skia-Bold, 16px, $font-color-dark, 22px, center);
        padding: 10px 0 0;
    }

    .instruction {
        @include basic-text($font-AvertaPE-Regular, 14px, $font-color-dark, 21px, center);
        padding: 5px 0;
    }
    .fishContainer{
        background-color: #EFF1F4;
        border-radius: 100%;
        width:116px;
        height:116px;
        @include justify-align-direction(center, center, column);
        img{
            width:74px;
            height:51px;
        }
    }
}
.sliderContainer{
    width: 100%;
    .card-slider .cardBody .species-name{
        @include basic-text($font-Skia-Bold, 12px, $font-color-dark, 14px, center);
    }
  
    .instruction{
        margin-bottom: 10px;
    }
}

.actions {
    padding: 2% 2% 0px;
    // @include justify-align(center, center);
    @include justify-align-direction(center, center, column);
    .actionContainer{
        width:220px;
    }
    .message {
        @include basic-text($font-Skia-Bold, 16px, $font-color-dark, 22px, center);
        padding: 10px 0 0;
    }

    .instruction {
        @include basic-text($font-AvertaPE-Regular, 14px, $font-color-dark, 21px, center);
        padding: 5px 0;
    }
    .fishContainer{
        background-color: #EFF1F4;
        border-radius: 100%;
        width:116px;
        height:116px;
        @include justify-align-direction(center, center, column);
        img{
            width:74px;
            height:51px;
        }
    }
}
.relatedfishContainer{
    width: 100%;
    margin-top: 10px;
    .card-slider .cardBody .species-name{
        @include basic-text($font-Skia-Bold, 12px, $font-color-dark, 14px, center);
    }
  
    .instruction{
        margin-bottom: 10px;
    }
}
