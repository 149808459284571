.number-card {
  @include flex-align(center);
  flex-direction: column;
  gap:10px;
  width: 100%;
  text-align: center;
  .dark-bg {
    background-color: $font-color-dark;
    width: 60px;
    height: 60px;
    @include tablet{
      width: 100px;
      height: 100px;
    }
    margin: 0 auto;
    border-radius: 12px;
    @include justify-align(center, center);
    img {
      width: 34px;
      height: 34px;
    }
    &.with-border{
      border:$border-width-2 solid $border-color-dark;
    }
  }
  .light-bg{
    background-color: $bg-white;
    width: 60px;
    height: 60px;
    @include tablet{
      width: 100px;
      height: 100px;
    }
    margin: 0 auto;
    border-radius: 12px;
    @include justify-align(center, center);
    img {
      width: 34px;
      height: 34px;
    }
    &.with-border{
      border:$border-width-2 solid $border-color-dark;
    }
  }
  .number-count {
    font-size: 23.04px;
    letter-spacing: 1px;
    color: $font-color-primary;
  }
  .species-name {
    font-size: 12px;
    line-height: 14px;
    display: inline-block;
    text-align: center;
    color: $font-color-white;
    // max-width: 42px;
    width: 100%;
  }
  .species-name-dark{
    font-size: 12px;
    line-height: 14px;
    display: inline-block;
    text-align: center;
    color: $font-color-primary;
    // max-width: 42px;
    width: 100%;
  }
}
