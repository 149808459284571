.card {
  display: flex;
  flex-direction: column;
  background-color: #fff;

  border-radius: 20px 20px 20px 20px;


  &.web-card {
    margin: auto;
    padding: 0;
    margin-bottom: 30px;
    .imgContainer {
      flex: 1;
    }

    .img {
      width: calc(100% + 30px);
      height: 100%;
      object-fit: cover;
      border-radius: 20px 0px 0px 20px;
    }

    .contentContainer {
      flex: 1 1;
      padding: 80px 50px;
      border-radius: 20px;
      background: $bg-secondary;
    }

    .dateContainer {
      width: 240px;
      display: flex;
      flex: 1;
      align-items: flex-end;
      justify-content: flex-end;
      .created {
        font-size: 11px;
        font-style: italic;
        margin-top: 0.5rem;
      }
    }

    ul {
      padding: 0;
    }

    li {
      font-family: $font-Skia-Bold;
      font-size: 14px;
      line-height: 21px;
      color: $font-color-primary;
      list-style-type: none;
    }
  }

  &.mobile-card {
    margin: 30px;

    .img {
      align-self: center;
      width: 100%;
      height: 180px;
      object-fit: cover;
      border-radius: 20px 20px 0px 0px;
    }

    .contentContainer {
      margin: 20px 20px 0px 20px;
    }

    ul {
      padding: 0;
    }

    li {
      font-family: $font-Skia-Bold;
      font-size: 12px;
      line-height: 18px;
      color: $font-color-dark;
      list-style-type: none;
    }
    .dateContainer {
      width: 240px;
      margin: 0 auto;
    }
  }

  @include tablet {
    flex-direction: row;
  }


&.contentBottom{
  margin: 20px 20px 0;
  

    @include tablet {
    position: relative;
    max-height: 441px;
    padding: 0;
    margin: 0;
    }
  .contentContainer{
    @include tablet {

      position: absolute;
      bottom: 30px;
      margin: 0 30px;
      padding: 30px 30px 0;
    }
  }
  &.web-card .img {
    @include tablet {
    width: 100%;
    border-radius: 0;
    }
  }
}

}