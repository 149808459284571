.page-faq {
    background: $bg-white;
    padding-top: 40px;
    .faq-wrapper{
      .faq-accordion-wrap{
        .faq-accordion-details{
          margin-bottom: 30px;
        }
      }
        
    }
    .faq-share-wrap{
      padding: 10px 0 20px 0;
      @include tablet{
      padding: 20px 0 70px 0;
      }
      .share-with-friends .impress-friends {
          background-color:$bg-secondary;
      }
    }
}