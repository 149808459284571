.sliderWapper {
  padding: 50px 0 0;
  .details-header {
    .MainTitleContent-wrap {
      .heading {
        font-size: 23.04px;
        line-height: 27px;
      }
      p {
        max-width: 1216px;
        width: 100%;
      }
    }
  }
}

.longCardParent {
  height: auto;
  width: 100%;
  background-color: $bg-secondary;
  border-radius: 20px;
  min-height: 700px;
  max-height: 700px;
  cursor: pointer;
  .image {
    height: 200px;
    width: 100%;
  
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; 
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
    
    }
  }
  .longContent-body{
    //min-height: 555px;
    max-height: 495px;
    overflow-y: scroll;
  }
  .section-one {
    padding: 20px 20px 10px 20px;
    .MainTitleContent-wrap {
      .heading {
        font-size: 23.04px;
        line-height: 27px;
        margin-bottom: 10px !important;
      }
      p {
        width: 100%;
      }
    }
  }
  .section-two{
    padding: 0 20px;
    text-align: left;
    .left-icon{
      @include justify-align(flex-start,center);
      font-size: 14px;
      line-height: 18px;
      color: $font-color-dark;
      padding-bottom: 10px;
      span{
        display:flex;
        width: 42px;
        height: 42px;
        color: $font-color-white;
        border-radius: 50px;
        @include justify-align(center,center);
      }
    }
  
  }
  .section-three {
    padding: 10px 20px 0;
    .MainTitleContent-wrap {
      .heading {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px !important;
      }
      p {
        // max-width: 320px;
        width: 100%;
      }
    }
  }
  .section-four {
    padding: 10px 20px 30px 20px;
    .MainTitleContent-wrap {
      .heading {
        font-size: 16px;
        line-height: 22px;
      }
      p {
        width: 100%;
      }
    }
    .data-list {
      .chamberList {
        @include flex-align(center);
        margin-bottom: 5px;
        .image {
          background-color: $bg-primary;
          border-radius: 50px;
          width: 42px;
          height: 42px;
          @include justify-align(center, center);
          img {
            width: 35.03px;
            height: 16px;
            object-fit: contain;
          }
        }
        .p-light {
          margin-bottom: 0px !important;
          padding-left: 10px;
        }
      }
    }
  }
}

.animalsDetails {
  .slick-slider {
    padding: 50px 0 0;
    .slick-slide {
      padding-right: 10px;
    }
    .slick-dots{
      li{
        margin: 0px;
        button{
          &::before{
            color: $font-color-white;
            font-size: 10px;
            opacity: unset;
          }
        }
        &.slick-active{
          button{
            &::before{
              color: $font-color-primary;
            }
          }
        } 
      }
    }
  }
  @include tablet {
    .slick-slider {
      padding: 50px 0 10px 0;
      .slick-slide {
        padding-right: 60px;
      }
    }
  }
}

