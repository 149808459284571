
@font-face {
    font-family: 'Skia';
    src: url('../fonts/Skia.ttf') format('truetype');
    font-weight: 1;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Skia-Bold';
    src: url('../fonts/Skia-Bold.woff2') format('woff2');
    font-weight: 1;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Skia-Regular';
    src: url('../fonts/Skia-Regular.woff2') format('woff2');
    font-weight: 1;
    font-style: normal;
    font-display: swap;
}
/* font/woff2 */

