.mobile-header {
    position: relative;
    width: 100%;
    z-index: 50;
    padding: 20px 0 20px 0px;
    height: 114px;
    background-color: $bg-white;
    &.bottom-border {
        border-bottom: 1px solid $border-color-onWhite-opacity;
    }
    .nav-menu {
        @include justify-align(space-between, center);
        .goToHome {
            height: 20px;
            width: 20px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 0;
                background: transparent;
            }
        }
        .customLink {
            @include justify-align-direction(space-between, center, row);
            .HeaderLink {
                @include basic-text($font-Skia-Bold, 14px, $font-color-primary, 21px, right);
                padding: 0 10px 2px 0;
            }
        }
    }
    .logo {
        max-width: 120px;
        width: 100%;
        object-fit: contain;
    }
}