.share-with-friends {
  text-align: left;
  @include tablet{
    margin: 0 2px;
  }
  .impress-friends {
    background-color: $bg-secondary;
    border-radius: 12px;
    width: 100%;
    @include justify-align(space-between, center);

    .left-content {
      margin-left: 0;
      padding-left: 20px;
      max-width: 173px;
      width: 100%;

      .PageHeadingContent-wrap {
        .heading {
          text-align: left;
          font-size: 23.04px;
          line-height: 27px;
          width: 100%;
        }
      }

      .PageHeadingContent-wrap {
        p {
          text-align: left;
        }
      }

      .button-wrap {
        max-width: 140px;
        width: 100%;
        padding: 0px;
      }
    }

    .right-image {
      position: relative;

      img {
        max-width: 333px;
        width: 100%;
        height: 266px;
        max-height: 266px;

        object-fit: cover;
      }
    }

    &.no-image {
      @include justify-align-direction(center, center, column);
      padding: 20px 20px 30px;

      .left-content {
        max-width: unset;
        padding: 0px 20px 0 20px;

        .PageHeadingContent-wrap {
          p {
            padding-bottom: 20px;
            text-align: center;
          }
        }

        .PageHeadingContent-wrap {
          .heading {
            text-align: center;
          }
        }
      }

      .custom-button {
        @include justify-align-direction(center, center, column);
        //margin-bottom: 30px;

        button {
          margin: 0 5px;
        }
      }

      .custom-para {
        font-size: 14px;
        color: $font-color-primary;
        padding-bottom: 10px;
        padding-top: 40px;
        text-align: center;
      }
    }
  }

.desktopLayout{
  display: flex;
  flex-direction: column !important;
  align-items: flex-end !important;
  div{
    display: flex;
    flex-direction: row !important;
  }
}
  @include tablet {
    .impress-friends {
      position: relative;

      .left-content {
        width: 50%;
        padding: 50px 0;
        margin-left: 50px;
        max-width: unset;

        .PageHeadingContent-wrap {
          .heading {
            font-size: 27.65px;
            line-height: 32px;
            max-width: 475px;
          }
        }
      }

      .right-image {
        padding-top: 30px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);

        img {
          max-width: 420px;
          width: 100%;
          max-height: 420px;
          min-height: 333px;
        }
      }

      &.no-image {
        @include justify-align-direction(space-between, center, row);
        .left-content {
          padding: 20px 30px;
          .PageHeadingContent-wrap {
            p {
              padding-bottom: 0px;
            }
          }
        }

        .right-image {
          padding: 0 50px;
        }

        .custom-button {
          @include justify-align-direction(center, center, row);
          margin: 0px;

          .button-1 {
            margin-top: 0px;
            margin-right: 10px;
          }

          button {
            margin: 0 5px;
          }
        }

        .PageHeadingContent-wrap {
          p {
            padding-bottom: 0px;
            text-align: left !important;
          }
        }

        .PageHeadingContent-wrap {
          .heading {
            text-align: left !important;
          }
        }

        .custom-para {
          text-align: end;
          padding-top: 0px;
        }
      }
    }
  }
}

.important_notice  {
 
  @include tablet {
    .impress-friends {

      .left-content {
        width: 100%;
 
      }
 
    }
  }
}