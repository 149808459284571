
.desktop-header {
  position: relative;
  z-index: 9;
  width: 100%;
  height: 140px;
  &.bg-color-for-inner-pages{
    background-color:$bg-white;
  }
  &.bg-primary{
    background-color:$bg-primary;
  }
  &.bg-transparent{
    background-color: transparent;
  }
  .logo {
    max-width: 200px;
    width: 100%;
    object-fit: contain;
  }
  .nav-menu {
    padding: 30px 0;
    @include justify-align(space-between, center);
  }

  .menu-btn {
    transition: transform 0.3s ease;
  }
  
  .menu-btn.menu-open {
    transform: rotate(180deg);
  }

  .menu-list {
    &.home-pages-link{
    ul {
      display: flex;
      list-style: none;
    }
    li {
        padding-right: 20px;
        cursor: pointer;
      a {
        font-size: 14px;
        line-height: 21px;
        color: $font-color-white;
        font-family: $font-Skia-Bold;
        transition: all .2s ease;
        &.active {
          color: $font-color-white;
          text-decoration: underline;
          text-underline-position: under;
        }
        &:hover{
          color: $font-color-white;
          text-decoration: underline;
          text-underline-position: under;
        }
      }
    }
  }
  &.inner-pages-link{
    ul {
      display: flex;
      list-style: none;
    }
    li {
        padding-right: 20px;
        cursor: pointer;
      a {
        font-size: 14px;
        line-height: 21px;
        color: $font-color-primary;
        font-family: $font-Skia-Bold;
        transition: all .2s ease;
        &.active {
          color: $font-color-primary-hover;
        }
        &:hover{
            color: $font-color-primary-hover;
        }
      }
    }
  }
  }
}
.hamburger-video video {
  position: absolute;
  left: 0;
  top: 34px;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.hamburger-menu{
  transform: translatex(-100%);
  transition: all 0.4s linear;
}
.hamburger-menu-active{
  transform: none;
}