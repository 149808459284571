.CardIconText {
    background: $bg-secondary;
    @include justify-align(space-between, center);
    cursor: pointer;
    //border: $thick-primary-border;
    border-radius: $border-oval;
    height: 60px;
    @include tablet {
        height: 261px;
        width: 261px;
        border-radius: $border-rectangle;
        @include justify-align(center, center);
        flex-direction: column;
        gap: 20px;
        margin: 0 auto;
    }
    .CardIconText-wrap {
        @include justify-align(flex-start, center);
        flex-direction: row;
        gap: 10px;
        width: 100%;
        padding: 10px;

        @include tablet {
            max-width: 148px;
            padding: 0;
            @include justify-align(center, center);
            flex-direction: column;
            gap: 20px;
        }


        .CardIconText-img {
            width: 42px;
            height: 42px;
            padding: 6px;
            @include justify-align(center, center);
            background: $bg-secondary;
            border-radius: $border-round;
            //border: 2px solid $bg-primary;

            @include tablet {
                width: 80px;
                height: 80px;
            }

            img {
                width: 100%;
                height: 100%;

                @include tablet {
                    padding: 10px;
                }
            }

        }
        p:hover{
            color:$font-color-primary-hover
        }
    }

    .dancing-arrow-container {
        width: 20px;

        @include tablet {
            display: none;
        }
    }
    &.hoverAnimationCard:hover {
        border: 2px solid #2DCCD3;
        .CardIconText-wrap {
        p{
            color:$font-color-primary-hover
        }
    }
    }
}