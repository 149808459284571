.lost-chamber-section {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  position: relative;
    @include tablet {
    padding-bottom: 120px;
    }
  .lost-chambers {
    @include justify-align-direction(center, center, column);
    padding-top: 40px;
    position: relative;
    z-index: 3;
    .left-content {
      width: 100%;
      .PageHeadingContent-wrap {
        .heading {
          font-size: 33.18px;
          line-height: 39px;
          max-width: 380px;
          width: 100%;
          margin: 0 auto;
        }
      }
      .PageHeadingContent-wrap {
        p {
          max-width: 380px;
          width: 100%;
          padding: 0px;
          margin: 0 auto;
        }
      }
      .desktop-buttons {
        display: none;
      }
    }
    .right-image {
      width: 100%;
      padding-top: 30px;
      position: relative;
      .map-image {
        max-width: 238px;
        width: 100%;
        height: 172px;
        display: block;
        margin: 0 auto;
      }
      .star-image {
        max-width: 201px;
        margin-top: 22px;
        width: 100%;
        height: auto;
        position: absolute;
        right: -20px;
        bottom: -50px;
      }
    }
    .mb-buttons {
      display: block;
      text-align: center;
      padding-top: 50px;
      .button-wrap{
        padding: 0px;
      }
    }
  }
  .bg-overlay {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(2, 10, 29, 0) 0%,
      rgba(2, 10, 29) 100%
    );
    z-index: 1;
    transform: rotate(-180deg);
  }
  @include tablet {
    // margin-bottom: 120px;
    .lost-chambers {
      width: 100%;
      @include justify-align-direction(space-between, flex-start, row);
      padding-top: 50px;
      position: relative;
      z-index: 3;
      .left-content {
        width: 50%;
        padding-top: 30px;
        .PageHeadingContent-wrap {
          .heading {
            margin: unset;
          }
        }
        .PageHeadingContent-wrap {
          p {
            padding: 20px 0;
            margin: unset;
          }
          .desktop-buttons {
            display: block;
          }
        }
      }
      .right-image {
        width: 50%;
        padding-top: 30px;
        .map-image {
          max-width: 590px;
          width: 100%;
          height: auto;
        }
        .star-image {
          max-width: 352px;
          width: 100%;
          height: auto;
          position: absolute;
          right: -20px;
          bottom: -100px;
        }
      }
      .mb-buttons {
        display: none;
      }
    }
    .button-wrap {
      padding: 0px;
    }
  }
}
