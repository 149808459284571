.socialShareContainer {
    // @include justify-align(center, center);
   // @include justify-align-direction(center, flex-start, column);
    padding: 5% 5% 0;
    overflow-y: scroll;
    height: calc(100vh - 50px);
    @include tablet{
        height:100%;
    }
    .actionContainer{
     margin-bottom: 15px;
    }
    .heading {
        // @include basic-text($font-Skia-Bold, 16px, $font-color-dark, 22px, left);
        padding: 10px 0 0;
    }

    .instruction {
        @include basic-text($font-AvertaPE-Regular, 14px, $font-color-dark, 21px, center);
        padding: 10px 0;
    }

    .shareActionContainer {
        padding: 10px 0;

        .react-share__ShareButton {
            margin: 0px 8px 0px 0px;
            svg {
                border-radius: 100%
            }
        }
    }

    .instruction {
        @include basic-text($font-AvertaPE-Regular, 14px, $font-color-dark, 16px, left);
    }

    .info {
        @include basic-text($font-AvertaPE-Regular, 12px, $font-color-dark, 14px, left);
        width: 80%;
        padding: 20px 0;

    }


    // slider style==
    .pledgeSlider{

    .cardImg{
        width: 100%;
    }
    .cardImg {  
        height: 300px;
        
        img{
            width: 100%;
            height:100%; 
            border-radius: 20px;
            object-fit: cover;
            object-position: 10% 10%;
        }
        &.nonActive{
            border: 10px solid white;
            border-radius: 30px;
        }
        &.active{
            border: 10px solid #eeeeee94;
            border-radius: 30px;
        }
      /* Adjust the margin value to add spacing */
      }
      .slick-track{
          height:300px;
      }
      .slick-slide {
        padding-right: 10px;
        height:300px;
      }
      .slick-slide:last-child {
        padding-right: 0;
      }
   
    }

}

