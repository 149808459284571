.attractionDetail-container {

    .meetDolphinSection {
        margin-top: 40px;
        margin-bottom: 40px;
        .p-dark{
            color:$font-color-dark
        }
    }

    .experience-container {
        background-color: #fff;
        margin: 30px;
    }

    // carouselWithPagination
    .carouselWithPagination {
        margin: 35px 0;

        @include tablet {
            margin: 80px 0;
        }
    }

    // Pagination style
    ul.pagination {
        display: flex;
        justify-content: center;
        margin: 0;

        @include tablet {
            padding-top: 40px;
        }

        li {
            list-style: none;
            border: 2px solid $bg-primary;
            border-radius: 4px;
            width: 40px;
            height: 40px;
            align-items: center;
            justify-content: center;
            display: flex;
            margin: 0 5px;
            @include basic-text($font-AvertaPE-Regular, 14px, $font-color-dark, 21px, center);
        }

        li.active {
            background: $bg-primary;
            border-radius: 4px;
            width: 40px;
            height: 40px;
            align-items: center;
            justify-content: center;
            display: flex;
            @include basic-text($font-AvertaPE-Regular, 14px, $font-color-white, 21px, center);

        }

        li.next,
        li.previous {
            width: auto;
            padding: 0 8px;
            border: 0px;
        }
    }

    .spaceTB40 {
        padding: 40px 20px;
    }
    .spaceTB4020 {
        padding: 40px 20px 0px;
    }


}
.rodal-close{
    font-size: 25px;
    color: #999;
    top: 10px;
    right: 25px;
    width: 16px;
    height: 16px;
}

.rodal-close:before, .rodal-close:after {
    position: absolute;
    content: "×";
    height: 0px;
    width: 100%;
    top: 0;
    left: 0;
    margin-top: 0;
    background: #999;
    border-radius: 100%;
    transition: background 0.2s;
    transform: none;
}