.floating-dot-image-container{
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    overflow: hidden;

    max-width: 1030px;
    margin: 0 auto;
    .floating-dot-image-wrap{
        @include justify-align(space-between,center);
        gap:26px;
        position: relative;
        @include tablet{
            gap: 90px;
        }
        .dotImage{
            position: absolute;
            bottom: 0;
            left: 0;
            width: calc(100% - 70px);
            // @include tablet{
            //     left: 200px;
            // }
            img{
                width: 100%;
                @include tablet{
                    width: auto;
                }
            }
        }
        
        .floating-details-wrap{
            padding-left: 20px;
            z-index: 2;
            height: auto;
            @include flex-align(center);
            @include tablet{
                padding-left: 70px;
                max-width: 449px;
                height: 300px;
            }
          
    
        }
        .floating-image-wrap{
            width: 240px;
            height: 240px;
            z-index: 2;
            max-height: 240px;
            min-width: 240px;
            margin-right: -120px;
            @include tablet{
                width: 340px;
                height: 340px;
                max-height: 340px;
                min-width: 340px;
                margin-right: 0;
            }
        img{
            width: 100%;
            height: 100%;
            border-radius: $border-round;
            // &:hover{
            //     animation: shake 0.5s linear;
            // }
        }
        }
        &:nth-child(even){
            transform: rotateY(180deg);
            .MainTitleContent-wrap {
                transform: rotateY(180deg);
            }
        }
    }
}

@keyframes shake {
    0% { transform: rotate(0deg); }
   
    30% { transform: rotate(-4deg); }
   50% { transform: rotate(0deg); }
    80% { transform: rotate(4deg); }
    
    100% { transform: rotate(0deg); }
    
  }