.carouselWithPagination {
    margin: 35px 0;

    @include tablet {
        margin: 80px 0;
    }
    .sc-EHOje.dkKPsQ{
        margin: 0px;
    }
    // Pagination style
    ul.pagination {
        display: flex;
        justify-content: center;
        margin: 50px 0;
        padding: 0;
        li {
            list-style: none;
            border: 2px solid $bg-primary;
            border-radius: 4px;
            width: 40px;
            height: 40px;
            // align-items: center;
            // justify-content: center;
            // display: flex;
            @include justify-align(center,center);
            margin: 0 5px;
            @include basic-text($font-AvertaPE-Regular, 14px, $font-color-white, 21px, center);
            a{
                width: 100%;
            }
        }
        li.active {
            background: $bg-primary;
            border-radius: 4px;
            width: 40px;
            height: 40px;
            align-items: center;
            justify-content: center;
            display: flex;
        }

        li.next,
        li.previous {
            width: auto;
            padding: 0 8px;
            border: 0px;
            @include tablet{
                display: none;
            }
        }
    }
}