.pledgeFormContainer {
    padding: 5% 5% 0;

    @include tablet {
        padding: 3% 3% 0;
    }

    .error {
        @include basic-text($font-AvertaPE-Regular, 10px, $font-color-error, 14px, left);
        position: relative;
        bottom: 6px;
        left: 20px;
    }

    .field {
        @include basic-text($font-AvertaPE-Regular, 14px, $font-color-blue, 21px, left);
        background-color: $bg-grey;
        border-radius: 20px;
        border: 0px;
        width: 100%;
        height: 44px;
        padding: 10px 20px;
        margin: 5px 0
    }

    .field::placeholder {
        color: $font-color-blue;
    }

    .textAreaField {
        @include basic-text($font-AvertaPE-Regular, 14px, $font-color-blue, 21px, left);
        background-color: $bg-grey;
        border-radius: 20px;
        border: 0px;
        width: 360px;
        height: 70px;
        padding: 10px 20px;
        margin: 5px 0
    }

    .formSection {
        margin: 15px 0 30px 0;
    }


    .switchButton {
        position: relative;
        display: inline-block;
        width: 360px;
        height: 42px;
        border-radius: 20px;
        background-color: #ccc;
        cursor: pointer;
        border: 1px solid #EEF1F6;
        margin-bottom: 10px;
    }

    .switchButton.on {
        background-color: #fff;
    }

    .switchButton.off {
        background-color: $font-color-white;
    }

    .slider {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 165px;
        height: 40px;
        border-radius: 20px;
        background-color: #5bc0de;
        transition: transform 0.3s ease-in-out;
        @include justify-align(center, center);
    }

    .switchButton.on .slider {
        transform: translateX(195px);
    }

    .switchButtonPick,
    .switchButtonWrite {
        width: 165px;
        height: 40px;
        border-radius: 20px;
        background-color: $bg-white;
        transition: transform 0.3s ease-in-out;
        border: 0px
    }

    .slideButtonText {
        @include basic-text($font-Skia-Bold, 12px, $font-color-blue, 14px, center);
    }

    .checkIt {
        @include basic-text($font-AvertaPE-Regular, 12px, $font-color-dark, 14px, left);
        width: 18px;
        height: 20px;
        display: flex;
        width: 100%;
        align-items: flex-start;
        margin: 20px 0 10px 0;

        p {
            padding-left: 10px;
            width: 90%;
        }
        @include tablet{
            align-items: center;
        }
    }

    .checkIt input:checked {
        background-color: $font-color-cyan;
        position: relative;
    }

    .checkIt input:checked::before {
        content: "\2714";
        color: #fff;
        position: absolute;
        right: 1px;
    }

    .checkIt input[type="checkbox"] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 18px;
        height: 18px;
        border: 1px solid $link-color;
        border-radius: 4px;
        outline: none;
        transition: border-color 0.3s;
    }

    .modalFooter {
        display: flex;
        flex-direction: column;
        border-top: 1px solid $bg-grey;
        padding: 30px 0;

        .note {
            @include justify-align(center, center);
            @include basic-text($font-AvertaPE-Regular, 12px, $font-color-light-onWhite, 14px, center);

            @include tablet {
                margin: 10px 0;
            }
        }

        .submitAction {
            @include justify-align(center, center);
            flex: 1;

            button {
                width: 60%;
            }
        }

        @include tablet {
            flex-direction: row;
            padding: 20px 0;
        }
    }

    .pledgeDropdown {
        .Dropdown-control {
            background-color: #EEF1F6;
            height: auto;
            border-radius: 20px;
            border: 0;
            padding: 15px 38px 15px 20px;
            min-height: 77px;
        }
        .Dropdown-option {
            box-sizing: border-box;
            color: rgba(51, 51, 51, 0.8);
            cursor: pointer;
            display: block;
            padding: 8px 10px;
            background-color: #EEF1F6;
            font-size: 14px;
            line-height: 16px;
            border-bottom: 0.5px solid #ccc;
        }
        .Dropdown-placeholder{
            @include basic-text($font-AvertaPE-Regular, 14px, $font-color-blue, 16px, left);
           
        }
        .Dropdown-arrow {
            border-color: #999 transparent transparent;
            border-style: solid;
            border-width: 5px 5px 0;
            content: ' ';
            display: block;
            height: 0;
            margin-top: -ceil(2.5);
            position: absolute;
            right: 10px;
            top: 14px;
            width: 0
        }

        .is-open .Dropdown-arrow {
            border-color: transparent transparent #999;
            border-width: 0 5px 5px;
        }

        .arrow-closed,
        .arrow-open {
            border: solid #2DCCD3;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 4px;
            position: absolute;
            right: 20px;
        }

        .arrow-closed {
            top: 12px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
        }

        .arrow-open {
            top: 12px;
            transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg);
        }

        .Dropdown-menu {
            background-color: #EEF1F6;
            border: 0px;
            top: 100%;
            left: 15px;
            width: 90%;
        }
    }
}