
.page-experiences{
    @include tablet{
    // padding-top: 180px;

    }
    .experiences-container{
    padding: 40px 20px;
    @include tablet{
        padding: 40px 20px 0;
    }
    .Experiences-wrap{
        background-color: $bg-white;
        border-bottom: $border-bottom-white;
        margin-bottom: 70px;
        &:last-child{
        border-bottom: 0;
        margin-bottom: 0;

        }
        .experiences-content-web{
            @include  list-view-small();
            row-gap: 70px;
            column-gap: 24px;
            padding-bottom:70px;

            .content-grid-wrap{
                
                .content-details-wrap{
                    p{
                      @include basic-text($font-AvertaPE-Regular, 14px, $font-color-dark, 21px, left);
                    }
                  }
                .content-grid-body{
                    .p-dark{
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 4;
                        overflow: hidden;
                    }
                }

            }
            
        }
    }
    }
}


