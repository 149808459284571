.home-banner {
  // margin-top: -9rem;
  position: relative;
 // overflow-y: hidden;
  .video-container {
    position: relative;
    width: 100%;
    // height: 100%;
    height: 620px;

    @include tablet{
     height: 680px;
    }
    video{
      width:auto;
      height:100%;
      object-fit: cover;
      @include tablet{
      width:100%;
      object-fit: cover;
    }
    }
  }
  .userLoggedIN{
    height: 640px !important;
  }
  
  .video-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: linear-gradient(180deg,  transparent 90%, black 100%);
    background: linear-gradient(0deg, rgba(2, 10, 29, 0.30) 0%, rgba(2, 10, 29, 0.30) 100%);
    z-index: 1;

    @include tablet{
      background:linear-gradient(0deg, rgba(2, 10, 29, 0.30) 0%, rgba(2, 10, 29, 0.30) 100%);
      // background:linear-gradient(to bottom, black 5% ,transparent 30% 80%, black 100%);
    }
  }

  


  .gradient-box {
    background: linear-gradient(
      0deg,
      rgba(2, 10, 29, 0.9),
      rgba(2, 10, 29, 0.9)
    );
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    opacity: 0.8;
    @include tablet{
    //height: 1280px;
    }
  }
  .banner-content {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    .top-text {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: $font-color-light-onDark;
      margin: 0 auto;
      background-color: rgba(2, 10, 29, 0.5);
      border-radius: 4px;
      padding: 3px 12px 3px 6px;;
      max-width: max-content;
      width: 100%;
      .highlight {
        color: $font-color-primary;
        font-weight: 700;
        padding-left: 6px;
      }
    }
    strong {
      font-size: 12px;
      line-height: 14px;
      color: $font-color-primary;
    }
    h2 {
      font-size: 23.04px;
      line-height: 23px;
      text-align: center;
      letter-spacing: 1px;
      font-weight: 400;
      max-width: 265px;
      width: 100%;
      color: $font-color-white;
    }
    p {
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      max-width: 307px;
      width: 100%;
      color: $font-color-white;
      padding: 1rem;
    }
    a {
      font-size: 14px;
      line-height: 14px;
      text-align: center;
      max-width: 204px;
      width: 100%;
      text-decoration: underline;
      color: $font-color-white;
      padding-top: 36px;
    }
  }
  .desktop-banner-content {
    .banner-content {
      @include justify-align-direction(center, center, column);

    }
  }
  .mobile-banner-content {
    padding-top: 60px;
    @include tablet{
    padding-top: 236px;
    }
    @include justify-align-direction(center, center, column);
    width: 100%;
  }
}

@media (min-width: 768px) {
  .home-banner {
    // margin-top: -140px;
    .gradient-box {
      opacity: 0.6;
    }
    .video-bg {
      height: 640px;
    }
    .banner-content {
      // padding-top: 236px;
      width: 100%;
      padding-bottom: 30px;
      h2{
        max-width: 785px;
        width: 100%;
        letter-spacing: 0px;
        font-size: 39.81px;
        line-height: 40px;
      }
      p {
        max-width: 450px;
        width: 100%;
      }
      a {
        max-width: 306px;
        width: 100%;
      }
      .total-count {
        // background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(2px);
        font-size: 60px;
        line-height: 60px;
        max-width: 224px;
        width: 100%;
        text-align: center;
        display: inline-block;
        // border-radius: 12px;
        color: $font-color-white;
        margin-top: 42px;
        margin-bottom: 22px;
        padding: 10px 18px;
        // border:$border--full-white;
      }
      .marine-species {
        font-size: 23.04px;
        line-height: 23px;
        letter-spacing: 1px;
        color: $font-color-white;
      }
    }
  }
 
}

.home-activity{
  position: relative;
  margin: 0px 30px 20px;
  // .home-activity{
  //   margin: 160px 20px 20px;
  // }
  @include tablet{
  margin: 0px 0;
  }
  .activity-slider{
    background-color: $bg-white;
    border-radius: 20px;
    padding: 30px 0px 2px;
    @include tablet{
      padding: 30px 90px 20px;
      border-radius: 0;
    }
  }
}
@media (max-width: 768px) {
  .home-banner{
    .banner-content {
      padding-top: 130px;
    }
  }
 
}
