.ImageBannerWithGradient {
  
    background-size: cover;
    background-position: center;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    //align-items: center;
    flex-direction: column;
    @include tablet{
        .mainContainer{
            flex-direction: row;
            background-size: cover;
            background-position: center;
            background-size: cover;
            background-position: center;
            display: flex;
            justify-content: center;
        }
    }
    &.noBgImage{
        background: $bg-primary;
    }
    &.large{
       // height: 500px;
       //padding:30px 0 50px;
       @include tablet{
        padding:80px 0 50px;
       }
    }
    &.small{
        height:480px;
    }
    .headings{
        padding:40px 32px 20px;
        @include tablet{
            padding:0px 0px 20px;
        }
    }
    .mapSection{
        height: auto;
        @include justify-align-direction(center, center, column);

        img{
            height:210px;
            @include tablet{
                height:350px;
            }
        }
       
        
    }
    button{
        width:200px
    }
    .downloadLink{
        @include basic-text($font-Skia-Bold, 14px, $link-color, 16px, center);
        text-decoration-line: underline;
        @include tablet{
            padding-left:5px
        }
        &:hover {
            color: #21a0a6;
          }
    }
   
    
}