

@font-face {
    font-family: 'Roboto-Italic';
    src: url('../fonts/Roboto-Italic.eot');
    src: url('../fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Italic.woff2') format('woff2'),
        url('../fonts/Roboto-Italic.woff') format('woff'),
        url('../fonts/Roboto-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Roboto-Thin';
    src: url('../fonts/AvertaPE-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-ThinItalic';
    src: url('../fonts/AvertaPE-ThinItalic.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Light';
    src: url('../fonts/AvertaPE-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-LightItalic';
    src: url('../fonts/AvertaPE-LightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AvertaPE-Regular';
    src: url('../fonts/AvertaPE-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('../fonts/Roboto-Medium.eot');
    src: url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/Roboto-Medium.woff') format('woff'),
        url('../fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-MediumItalic';
    src: url('../fonts/Roboto-MediumItalic.eot');
    src: url('../fonts/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-MediumItalic.woff2') format('woff2'),
        url('../fonts/Roboto-MediumItalic.woff') format('woff'),
        url('../fonts/Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Black';
    src: url('../fonts/AvertaPE-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Roboto-BlackItalic';
    src: url('../fonts/AvertaPE-BlackItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AvertaPE-BoldItalic';
    src: url('../fonts/AvertaPE-BoldItalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'AvertaPE-Bold';
    src: url('../fonts/AvertaPE-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}