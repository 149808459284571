.lg\:flex-row {
  flex-direction: row;
}
.footer-wrapper{
  @include tablet{
  position: fixed;
  position: fixed;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: auto;
  }
}

.Footer-section {
  padding-top: 50px;
  background-color: $bg-white;

  ul {
    list-style: none;
  }
  .section-one {
    @include justify-align-direction(center, center, column);
    img {
      max-width: 197px;
      width: 100%;
      height: 72px;
      display: block;
      margin: 30px auto 0;
    }
    .title {
      text-align: center;
     
    
      @include flex-direction(column);
      p{
        margin-top: 15px;
        @include tablet{
          margin-top: 0px;
        }
      }
    }
    h4 {
      font-size: 23.04px;
      line-height: 27px;
      letter-spacing: 1px;
      margin: 0 auto;
      color: $font-color-primary;
      max-width: 269px;
      width: 100%;
    }
    p {
      font-size: 14px;
      line-height: 21px;
      max-width: 344px;
      width: 100%;
      margin: 0 auto;
      color: $font-color-dark-onLight;
    }
    .buttons {
      text-align: center;
      margin: 0 auto;
      .button-wrap {
        &:first-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  .section-two {
    display: none;
  }
  .section-two-mb {
    display: block;
    .footer-submenu {
      ul {
        padding-left: unset;
        li {
          border-bottom: 1px solid rgb(121, 127, 141, 0.2);
          .footer-submenu-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 0 10px;
          }
          span {
            font-size: 14px;
            line-height: 21px;
            color: $font-color-primary;
            font-family: $font-Skia-Bold;
          }
         
        }
      }
    }
  }
  .section-three {
    padding: 40px 0 30px 0;
    text-align: center;
    img {
      max-width: 197px;
      width: 100%;
      height: 75px;
      cursor: pointer;
      object-fit: contain;
    }
    .social-icons {
      margin: 0 auto;
      padding: 16px 0;
      @include justify-align(center,center);
      gap: 18px;
      .tag-social {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
       
      img {
        width: 41px;
        height: 41px;
        cursor: pointer;
      }
    }
    h4 {
      max-width: 263px;
      width: 100%;
      font-size: 23.04px;
      line-height: 27px;
      letter-spacing: 1px;
      color: $font-color-primary;
      margin: 0 auto;
    }
    .mobile-logo {
      display: none;
    }
  }
  .section-four {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .bottom-footer {
      margin: 0 auto;
      text-align: center;
    }
    p {
      font-size: 12px;
      line-height: 24px;
      color: $font-color-primary;
    }
    span {
      margin: 0 auto;
      display: flex;
      color: $font-color-light-onWhite;
      opacity: 0.5;
      font-size: 12px;
      line-height: 14px;
      margin: 30px 0 35px;
    }
  }
}

.grid {
  display: grid;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

@media (min-width: 768px) {
  .footer-video {

    video {
      width: 100%;
      max-height:480px;
      object-fit: cover;
      margin-bottom: -4px;
    }
  }
  .Footer-section {
    .section-one {
      padding-bottom: 40px;
      border-bottom: 1px solid rgb(121, 127, 141, 0.2);
      @include justify-align-direction(space-between, center, row);
      .buttons {
        margin: unset;
        max-width: unset;
        .Check-btn {
          margin-top: 10px;
          max-width: unset;
        }
        .visit-btn {
          margin: unset;
          max-width: unset;
        }
        img {
          display: none;
        }
      }
      h4 {
        margin: unset;
        font-size: 33.18px;
        line-height: 39px;
        color: $font-color-primary;
        max-width: unset;
      }
      p {
        max-width: unset;
        margin: unset;
        padding: 14px 0;
      }
      .title {
        text-align: unset;
      }
    }
    .section-two {
      display: block;
      padding: 40px 0 20px;
      border-bottom: 1px solid rgb(121, 127, 141, 0.2);
      .footer-menu {
        h6 {
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 1px;
          color: $font-color-primary;
          cursor: pointer;
          transition: all 0.3s ease-in;
          &:hover{
            padding-left: 4px;
          }
        }
        ul {
          padding: 0;
          li {
            padding-bottom: 10px;
            &:hover {
              div{
              color: #21a0a6;
              }
            }
            div {
              font-size: 14px;
              line-height: 21px;
              color: $font-color-primary;
              font-family: $font-Skia-Bold !important;
              cursor: pointer;
              transition: all 0.3s ease-in;
              &:hover{
                padding-left: 4px;
              }
            }
          }
        }
        &.main-footer-links{
          h6 {
            padding-bottom: 20px;
          }
          ul {
            margin: 0;
            li {
              padding-bottom: 0;
            }
          }
        }
      }
      .title {
        text-align: unset;
      }
    }

    .section-two-mb {
      display: none;
    }
    .section-three {
      padding-bottom: 30px;
      border-bottom: 1px solid rgb(121, 127, 141, 0.2);
      @include justify-align-direction(space-between, center, row);
      .mobile-logo {
        display: block;
      }
      .tag-social {
        @include justify-align-direction(flex-end, center, row);
        gap: 20px;
      }
      h4 {
        display: flex;
        align-items: center;
        margin: unset;
        max-width: unset;
        width: unset;
        font-size: 16px;
      }
      .social-icons {
        margin: unset;
      }
    }
    .section-four {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .bottom-footer {
        padding: 30px 0;
        margin: unset;
        @include justify-align(center, center);
        div{
        p {
          display: inline;
          padding-right: 30px;
          position: relative;
          cursor: pointer;
          &::after {
            content: "";
            position: absolute;
            height: 30px;
            width: 1px;
            background-color: $font-color-light-onDark;
            margin-left: 20px;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
          }
        
        }
        &:last-child{
          p{
            &::after {
              display: none;
            }
          }
        }
      }
      }
      span {
        margin: unset;
      }
    }
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

 
}
@media (max-width: 768px) {
  .footer-video {
    video {
      width: 100%;
      height: auto;
      margin-bottom: -4px;
    }
  }
  .Footer-section {
    .section-four {
      padding-bottom: 100px;
    }
    .section-one {
      .buttons {
        .button-wrap {
          &:first-child {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
