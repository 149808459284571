.button-wrap {
    height: 44px;
    width: 100%;
    font-family: $font-Skia-Bold;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: $font-color-white;
    padding: 0 16px;
    @include justify-align(center,center);
    margin:20px 0;
    text-transform: capitalize;
    outline: none;
    cursor: pointer;

    &.btn-backgrounded{
    background: $bg-primary;
    border-radius: $border-primary;
    border: $bg-yellow;
    transition: all 0.3s linear;
    &:hover{
        background-color: $bg-primary-hover;
    }
    }
    &.btn-outlined{
        background: $bg-transparent;
        border-radius: $border-primary;
        border: $blue-colored-border;
        color: $font-color-white;
        transition: all 0.3s linear;
        color:$font-color-dark;
        &.btn-text-dark{
            color:$font-color-primary;
        }
        &.btn-text-light{
            color:$font-color-white;

        }
        &:hover{
            border: 2px solid $bg-primary;
            color: $font-color-white;
            background: $bg-primary;
        }
    }
    &.btn-white-outlined{
        background: $bg-transparent;
        border-radius: $border-primary;
        border: $white-colored-border;
        color: $font-color-white;
        transition: all 0.3s linear;
        color:$font-color-dark;
        &.btn-text-dark{
            color:$font-color-primary;
        }
        &.btn-text-light{
            color:$font-color-white;

        }
        &:hover{
            border: 2px solid $bg-white;
            color: $font-color-white;
            background: $bg-white;
            color:$font-color-primary;
        }
    }

    &.DrakHoverButton:hover {
        background: $bg-primary !important;
        border: 2px solid $bg-primary !important;
    }

}