* {
  outline: none !important;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  margin: 0px;
}

body {
  height: 100%;
  margin: 0px;
  padding-top: 0px;
  font-family: $font-AvertaPE-Regular;
  -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
  background-color: $bg-dark;
   width: 100%;
}
#root{
  overflow: hidden;
}
.dynamicMinHeight{
  min-height: calc(100vh - 200px);
}



// Animation to Shrink the contentSection
.root-container {
  &.shrinkAnimation {
    width: 100%;
    height: 584px;
    @include position-transform(fixed, right, -58%, top, 13%, scale(0.5));
    border: 6px solid white;
    border-radius: 67px;
    padding: 44px 10px;
    overflow: hidden;
    @include transition(1s ease-in-out);
    z-index: 99999999;
    @include media-width-min(538) {
      height: 755px;
      right: -48%;
      top: -1%;
    }
  }

  .overlay-wrapper {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    background: transparent;
    z-index: 99999;
    display: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button {
  margin: 0;
  padding: 0;
}

h1,h2,h3,h4,h5,h6 {
  font-family: $font-Skia-Bold
}


a {
  text-decoration: none;
  cursor: pointer;
}



select {
  font-family: "G-Regular";
  border: 1px solid #efefef;
  width: 280px;
  margin: 15px;
  padding: 15px;
}

.text-section {
  padding: 16px;
}

.btn-width {
  max-width: 321px;
}

.pointer {
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.container {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
} 
.GreyContainer{
  background-color: $bg-secondary;
}
.whiteContainer{
  background-color: $bg-white;
}
.NoPaddingContainer{
  //width: 100%;
 padding: 0px;
 margin-right: auto;
 margin-left: auto;
}
.container-fluid{
  width: 100%;
  margin: 0px;
  padding: 0px;
}
@media (min-width: 576px) {
  .container {
      padding-right: 12px;
      padding-left: 12px;
      width: 540px;
  }
  .NoPaddingContainer{
    width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
      width: 720px;
  }
  .NoPaddingContainer{
    width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
      width: 960px;
  }
  .NoPaddingContainer{
    width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
      width: 1140px;
  }
  .NoPaddingContainer{
    width: 1140px;
  }
}
  @media (min-width: 1800px) {
    .container {
      width: 1400px;
    }
    .NoPaddingContainer {
      width: 1400px;
    }
}


.d-center-m-left{
  text-align: left;
  @include tablet{
    text-align: center;
  }
}
.d-left-m-center{
  text-align: center;
  @include tablet{
    text-align: left;
  }
}
.d-m-center{
  text-align: center;
}
.d-m-left{
  text-align: left;
}


// Global classes 

a.Blue-Linktitle:hover {
  color: $font-color-primary-hover;
}
.bannerHeading{
  font-family: $font-AvertaPE-Regular;
  font-size: 40px;
  line-height: 44px;
  color: $font-color-white;
}
.GradientBlueLtoR{
    background: linear-gradient(90deg, $bg-primary 34.24%, rgba(1, 33, 105, 0)  94.1%);
}

.GradientBlueRtoL{
    background:linear-gradient(90deg, rgba(2, 10, 29, 0) 0%, $bg-primary 100%);
}
.GradientTtoB{
  background: linear-gradient(180deg, $bg-primary 0%, rgba(1, 33, 105, 0) 10%);
}
.GradientBtoT{
  background: linear-gradient(180deg, rgba(2, 10, 29, 0) 0%, $bg-primary 100%);

}
.paraWithLeftBorder {
    border-left: 5px solid $bg-white;
    padding-left: 20px;
}
.bg-grey{
    background-color: $bg-grey;
}
.bg-dark{
    background-color: $bg-dark;
}
.bg-secondary{
  background-color: $bg-secondary;
}
.bg-white{
    background-color: $bg-white;
}
.text-white{
  font-family: $font-Skia-Bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1px;
  color: $font-color-white ;
}
.text-primary{
  font-family: $font-Skia-Bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1px;
  color: $font-color-primary ;
}
.text-dark{
  font-family: $font-Skia-Bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1px;
  color: $font-color-dark ;
}


p {
  font-family: $font-AvertaPE-Regular;
  font-size: 14px;
  line-height: 21px;
  font-family: $font-AvertaPE-Regular;
}
.paragraph-bold{
font-family: $font-Skia-Bold;
font-weight: 700;
font-size: 14px;
line-height: 21px;
color: $font-color-white;
}
.small-text{
  font-family: $font-AvertaPE-Regular;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: $font-color-white;
}

h6{
font-family: $font-Skia-Bold;
font-weight: 700;
font-size: 16px;
line-height: 22px;
letter-spacing: 1px;
color: $font-color-white;
}
h5{
  font-family: $font-Skia-Bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1px;
  color: $font-color-white;
  @include tablet{
    font-size: 19.2px;
    line-height: 23px;
  }
}
h4{
  font-family: $font-Skia-Bold;
  font-weight: 700;
  font-size: 23.04px;
  line-height: 27px;
  letter-spacing: 1px;
  color: $font-color-white;
}
h3{
    @extend .paragraph-bold;
    @include tablet{
    font-family: $font-Skia-Bold;
    font-weight: 700;
    font-size: 27.65px;
    line-height: 32px;
    letter-spacing: 1px;
    color: $font-color-white;
    }
}
h2{
  font-family: $font-Skia-Bold;
  font-size: 33.18px;
  line-height: 39px;
  letter-spacing: 1px;
  color: $font-color-white;

}
h1{
  @extend h4;
  @include tablet{
    font-family: $font-Skia-Bold;
    font-size: 39.81px;
    line-height: 47px;
    letter-spacing: 0px;
    color: $font-color-white;
  }
}

// MODAL STYLE
.rodal-dialog {
  z-index: 100;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 70vh;
  border-top-left-radius:15px;
  border-top-right-radius: 15px;
  overflow: auto;
  @include tablet {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius:5px;
      border-radius: 5px;
      overflow: auto;
  }
}



.dynamic-orphan-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  margin: 20px;
  padding: 20px;  
  .item {
      grid-column: span 2;
      padding: 20px;
      
      //  Dealing with 2 orphan items 
          &:last-child:nth-child(3n - 1) {
              grid-column-end: -2;
          }
          
          &:nth-last-child(2):nth-child(3n + 1) {
              grid-column-end: 4;
          }
          
          // Dealing with single orphan 
          
          &:last-child:nth-child(3n - 2) {
              grid-column-end: 5;
          }
    }
}

.rodal-close{
  font-size: 30px;
  color: #999;
  top: 10px;
  right: 25px;
  width: 16px;
  height: 16px;
}

.rodal-close:before, .rodal-close:after {
  position: absolute;
  content: "×";
  height: 0px;
  width: 100%;
  top: 0;
  left: 0;
  margin-top: 0;
  background: #999;
  border-radius: 100%;
  transition: background 0.2s;
  transform: none;
}
.mobile-menu-visible{
  overflow-y: hidden;
  position: fixed;
  width: 100%;
}


.activeCardContent{
  cursor: pointer;
}
.noActiveCardContent{
  // pointer-events: none;
}
