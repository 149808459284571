.fish-library-container{
    padding:24px 0 58px;
.fish-list-category{
    width: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    padding-top: 10px;
    margin-left: 20px;
    .blue-icon-card {
       // margin-right: 30px;
        @include tablet{
            margin-right: 50px;
        }
    }
}
    .fish-list{
        padding: 30px 50px 40px;
    }
}