
.home-page-container{
  margin-top: -114px;

  @include tablet{
  margin-top: -140px;
  }
.scan-fish-camera {
  display: none;
}

.protect-marine-species {
  padding: 40px 0;
  height: 590px;
  .protect-marine {
    width: 100%;
    @include flex;
    gap: 70px;
    .left-content {
      flex: 1;
      @include tablet{
        padding-top: 20px;
      }
      .marine-left-text {
        border-radius: 12px;
        max-height: 430px;
         max-width: 535px;
     
        // padding: 0 0 0 20px;
        .MainTitleContent-wrap.themeDark h3 {
          padding-bottom: 14px !important;
        }
        .images-logo {
          margin-bottom: 20px;
          @include justify-align(space-around, center);
          width: 100%;
          @include tablet{
             @include justify-align(flex-start, center);
          }
          img {
            width: 187px;
            height: 188px;
            object-fit: contain;
            &:first-child {
              margin-right: 50px;
            }
          }
        }
     
        .Blue-Linktitle {
          padding: 0 20px;
        }
      }
    }
   

  }

}
.section-lost-chambers{
  min-height: 676px;
}


.lost-chamber-section-2 {
  background-image: url("../../assets/images/Lost_chamber_2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%; 
  height: 775px;
  position: relative;
  @include tablet{
    background-attachment: fixed;
    }
  .left-content {
    width: 50%;
    padding-top: 250px;
    position: relative;
    z-index: 3;
    .MainTitleContent-wrap .heading {
      //font-size: 33.18px;
      //line-height: 39px;
      max-width: 285px;
      width: 100%;
      @include tablet{
        font-size: 33.18px;
      }
    }
    .MainTitleContent-wrap p {
      max-width: 451px;
      width: 100%;
    }
    .button-wrap.btn-outlined {
      padding: 0;
    }
  }
  .bg-overlay {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // background: linear-gradient(
    //   0deg,
    //   rgba(2, 10, 29, 0.4),
    //   rgba(2, 10, 29, 0.4)
    // );
    //background: linear-gradient(0deg,rgb(2 10 29 / 41%),rgb(0 0 0 / 80%));
    background:linear-gradient(rgba(10, 124, 138,0.1) 10%, rgba(10, 124, 138,0.3) 50%, rgba(10, 124, 138,0.5) 90%,rgba(10, 124, 138,0.7) 100%);
    z-index: 1;
  }

}

// .lost-chamber-section-2 {
//   background-image: url("../../assets/images/Lost_chamber_2.png");
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   width: 100%; 
//   height: 775px;
//   position: relative;
//   //background-attachment: fixed;

//   @include tablet{
//     background-attachment: fixed;
//   }

//   .left-content {
//     width: 50%;
//     padding-top: 250px;
//     position: relative;
//     z-index: 3;
//     .MainTitleContent-wrap .heading {
//       font-size: 33.18px;
//       line-height: 39px;
//       max-width: 285px;
//       width: 100%;
//     }
//     .MainTitleContent-wrap p {
//       max-width: 451px;
//       width: 100%;
//     }
//     .button-wrap.btn-outlined {
//       padding: 0;
//     }
//   }
//   .bg-overlay {
//     content: "";
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     top: 0;
//     left: 0;
//     background: linear-gradient(
//       0deg,
//       rgba(2, 10, 29, 0.4),
//       rgba(2, 10, 29, 0.4)
//     );
//     z-index: 1;
//   }

// }



@media (max-width: 768px) {
 
  .protect-marine-species {
    height: 570px;
    .protect-marine {
      @include justify-align-direction(center, flex-start, column);
      gap: 40px;
      .left-content {
        width: 100%;
        .marine-left-text {
          max-width: 296px;
          max-height: 430px;
          margin: 0 auto;
          text-align: center;
          .images-logo {
            img {
              width: 123px;
              height: 133px;
              &:first-child {
                margin-right: unset;
              }
            }
          }
        }
      }
    
    }
  }
  img.img-small-species.aos-init.aos-animate {
    margin-top: 35px;
  }
  img.img-large-species.aos-init.aos-animate {
    margin-top: 40px;
  }
  .lost-chamber-section-2 {
    // margin-top: 150px;
    min-height: unset;
    height: 580px;
    background-image: none;
  
    .left-content {
      width: 100%;
      padding-top: 60px;
      position: unset;
      top: -40%;
      max-width: 350px;
      @include justify-align-direction(center, center, column);
      height: 680px;
      .MainTitleContent-wrap {
        .heading {
          max-width: 263px;
          width: 100%;
          margin: 0 auto;
        }
        .p-dark{
          margin-bottom: 0;
        }
      }
      .MainTitleContent-wrap p {
        max-width: 305px;
        margin: 0 auto;
      }
      .explore-btn{
        margin: 0 auto;
      }
      a {
        margin: 0 auto;
      }
    }
  }
  .scan-fish-camera {
    display: block;
    .scan-fish-section {
      background-color: $bg-primary;
      border-radius: 20px;
      margin: 0 0 20px;
      @include justify-align-direction(center, center, column);
      padding: 40px 30px 20px 30px;
      .camera-logos {
        @include flex-justify(space-between);
        padding-bottom: 20px;
        width: 100%;
        .img-text {
          @include justify-align(left, center);
          .p-white {
            padding-left: 10px;
            max-width: 83px;
            width: 100%;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 0;
          }
          &:first-child::after {
            content: "";
            position: absolute;
            background: $bg-white;
            opacity: 0.2;
            width: 1px;
            height: 40px;
            left: 50%;
            transform: translateX(-50%);
          }
         
        }
      }
      .Title-sub-blue {
        padding: 10px 0;
       
      }
      .p-dark {
        margin-bottom: 0px;
      }
      .scan-fish-img {
        width: 105px;
        height: 105px;
        object-fit: cover;
        margin: 24px 0 10px;
      }
      .button-wrap{
        width: 145px;
      }
    }
  }
}



.home-activity2{
  background-color: $bg-white;
  .activity-section {
    .activity-slider{
      padding: 0px;
      .card-slider{
        .cardBody{
          .species-name{
            display: inline;
            margin: 0 auto !important;
          }
        }
      }
    }
  }
}
.home-lost-chamber{
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;

  @include tablet {
    height: 650px;
  //padding-top: 100px;
  }
    .lost-chamber-section{
      height: auto;
      @include tablet {
      height: 775px;
      }
    }
  }
  .lost-chambers{
    z-index: 2;
    position: relative;
    padding-bottom: 70px;
  }
  .mobile-bottom-bg-wrap{
    content: "";
    position: absolute;
    width: 100%;
    background-image: url(../../assets/images/homepage/chambers-bg-mob.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    height: 100%;
    bottom: 0;
    top: unset;
 
    left: 0;
    @include tablet{
      display: none;
    }
  }
  .share-with-friends {
    .impress-friends {
      padding: 40px 20px;
      border-radius: 0;
      @include tablet {
      border-radius: $border-rectangle;
      padding: 0px 20px;

      }
  }
  }
}
.bluebanner{
  &.container{
    padding: 0;
    @include tablet{  
      padding: 80px 0;
    }
  }

  
}
.protect-marine{
.right-image{
  flex: 1;
  z-index: 3;
  position: relative;
  width: 100%;
  padding-left: 30px;
  .img-large-species{
    width: 186px;
    height: 186px;
    @include  tablet{
    width: 445px;
    height: 445px;
    }
  }
  .img-small-species{
    width: 105px;
    height: 105px;
    position: absolute;
    top: -40px;
    @include  tablet{
    width: 250px;
    height: 250px;
    top: -100px;

    }
    
  }
}
.protect-marine-btn-wrap{
  width: 160px;
  margin: 0 auto;
  @include tablet{
    margin:0;
  }
}
}
.home-page-container{
  .fun-facts-conatiner{
    padding: 0;
    @include tablet{
      padding: auto;
    }
  }
}