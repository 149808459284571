/* Hide scrollbar for modern browsers */
::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}

/* Hide scrollbar for Firefox */
body {
  scrollbar-width: none;
}

/* Hide scrollbar for Internet Explorer and Edge */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

/* Hide scrollbar on mobile screens */
@media screen and (max-width: 767px) {
  body {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
}


