.banner {
    display: flex;
    flex-direction: row;
    height: 520px;
    flex-grow: 2;

    &-Image {
        background-size: cover;
        background-repeat: no-repeat;
        align-items: flex-end;
        display: flex;
        justify-content: space-between;
        background-position: center center;

        .box-1 {
            width: 740px;
            height: 140px;
            display: flex;
            align-items: center;
            padding-left: 40px;
            margin-right: 40px;
            .bannerHeading {
                font-family: $font-Skia-Bold;
            }
        }

        .box-2 {
            width: 40%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            position: relative;

            .bannerDescription {
                min-width: 254px;
                position: absolute;
                right: 47px;
            }
        }
    }

    @include media-width-max(1125) {
        height: 520px;
    }

    @include media-width-max(758) {
        height: 341px;
    }
}