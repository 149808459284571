.conservation-section {
  .MainTitleContent-wrap {
    .heading {
      font-size: 27.65px;
      line-height: 32px;
      margin-bottom: 0px;
      margin-top: 20px;
    }
  }
  .conservation-status {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 450px;
    width: 100%;
    &.active {
      display: grid;
      grid-template-columns: auto;
      width: 100%;
      justify-items: center;
      align-items: center;
    }
    .icons-list {
      margin: 10px 15px;
      @include flex-direction(column);
      align-items: center;
      img {
        width: 28px;
        height: 28px;
        object-fit: cover;
      }
      .icons-title-dark {
        max-width: 56px;
        display: inline;
        width: 100%;
        padding-top: 10px;
        @include basic-text(
          $font-AvertaPE-Regular,
          12px,
          $font-color-dark,
          14px,
          center
        );
      }
      .icons-title-light {
        max-width: 56px;
        display: inline;
        width: 100%;
        padding-top: 10px;
        @include basic-text(
          $font-AvertaPE-Regular,
          12px,
          $font-color-dark-onLight,
          14px,
          center
        );
      }
    }

    .active-icons {
      margin: 0px 0px 20px;
      @include justify-align-direction(center, center, row);
      img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        margin-right: 10px;
      }
      .icons-title-dark {
        max-width: 56px;
        display: inline;
        width: 100%;
        margin: 0 auto;
        padding-top: 10px;
        @include basic-text(
          $font-AvertaPE-Regular,
          14px,
          $font-color-dark,
          18px,
          center
        );
      }
      .icons-title-light {
        max-width: 56px;
        display: inline;
        width: 100%;
        margin: 0 auto;
        padding-top: 10px;

        @include basic-text(
          $font-AvertaPE-Regular,
          14px,
          $font-color-primary,
          18px,
          center
        );
      }
    }
  }

  .conservation-status.dynamic-grid{
    grid-template-columns: repeat(5, 1fr);
    max-width: 450px;
    width: 100%;
    .icons-list{
      margin: 10px 5px;
    }
    .icons-title-dark{
      color: $font-color-white;
      @include tablet{
        color:$font-color-dark;
      }
    }
    
  }

  @include tablet {
    .conservation-status {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      max-width: 450px;
      width: 100%;
      text-align: left;
      .icons-list {
        margin: 10px;
        @include flex-direction(column);
         align-items: center;
        img {
          width: 60px;
          height: 60px;
          object-fit: cover;
         

        }
      }
    }
  }
}
