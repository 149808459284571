.mobile-header {
  position: relative;
  width: 100%;
  z-index: 50;
  padding: 40px 0 20px 0px;

  &.bottom-border {
    border-bottom: 1px solid $border-color-onWhite-opacity;
  }

  .menu-btn {
    width: 42px;
    height: 42px;
    z-index: 2;
    border-radius: 100%;
    background: rgba(204,209,219, 0.2);
    border: 0;
    color: #2DCCD3;
    transition: transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-btn.menu-open {
    transform: rotate(90deg);
  }

  .scan-btn {
    width: 22px;
    height: 42px;
  }
  .scan-btn-white {
    width: 22px;
    height: 42px;
  }

  .nav-menu {
    // height: 38px;
    @include justify-align(space-between, center);
  }

  .logo {
    max-width: 120px;
    width: 100%;
    object-fit: contain;
    position: relative;
    z-index: 99;
  }
}