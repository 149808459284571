.hamburger-content-container {
    width: 100%;
    background-image: url("../.././assets/images/line-dotted.png");
    height: 100%;
    background-repeat: no-repeat;
    background-position: top;
}

.hamburger-menu {
    position: relative;
    top: -120px;
    background-color: $bg-dark;

    .hamburger-video{
        height:100vh;
        background: $bg-primary;
        z-index: 0;
    }
    .hamburger-wrapper {
        // position: absolute;
        // top: 145px;
        // width: 100%;
        // height:calc(100vh - 115px);

        position: absolute;
    top: 135px;
    width: 100%;
    height: calc(100vh - 180px);
    // height: calc(100% - 120px);
    padding: 22px 0 0 0;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 1;

        .hamburger-menu-wrapper {
            width: 100%;
            height: 100%;
            position: relative;

            .dotted-bg {
                position: absolute;
                top: 0;
                left: 23px;
                width: 100%;
                height: 100%;
            }

            .menu-image-container {
                @include flex();
                position: relative;

                ul {
                    // position: absolute;
                    list-style: none;

                    li {
                        padding-bottom: 10px;
                        div.menu-item {
                            color: $font-color-primary;
                            font-size: 14px;
                            font-weight: 700;
                        }
                        &:first-child {
                            div.title{
                            color: #ffffff;
                            font-size: 16px;

                            }
                        }
                    }
                }


                .image {
                    background-color: $bg-primary;
                    width: 64px;
                    height: 64px;
                    border-radius: $border-round;
                    @include justify-align(center, center);
                    position: absolute;
                }

                // &:nth-child(1) {
                //     .image {
                //         left: 30px;
                //     }
                // }

                &:nth-child(2) {
                    .image {
                        left: 40px;
                        top: -20px;
                    }

                    ul {
                        padding-left: 120px;
                        margin-top: 0px;
                    }
                }

                &:nth-child(3) {
                    .image {
                        left: 5px;
                        top: -20px;
                    }

                    ul {
                        padding-left: 84px;
                        margin-top: 5px;
                    }
                }

                &:nth-child(4) {
                    .image {
                        left: 18px;
                    }

                    ul {
                        padding-left: 110px;
                    }
                }

                &:nth-child(5) {
                    .image {
                        left: 102px;
                        top: -7px;
                    }

                    ul {
                        position: absolute;
                        left: 145px;
                        top: -15px;
                    }
                }

                &:nth-child(6) {
                    .image {
                        left: 210px;
                        top: 58px;
                    }

                    ul {
                        position: absolute;
                        top: 55px;
                        left: 254px;
                    }
                }
            }
        }
    }
}
.showContent{
    position: fixed !important;
    top: 0 !important;
}