.category-list {
  @include flex-justify-direction(space-between, row);
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  background-color: $bg-white;
  padding: 5px 0;
  @include tablet{
    overflow-x: unset;
  }

  .blue-icon-card {
    //margin-right: 30px;
    @include tablet {
      margin-right: 50px;
    }
  }
}
.category-border {
  border-bottom: 1px solid $border-color-onWhite-opacity;
  position: sticky;
    // top: 133px;
    top: 0px;

  z-index: 2;
  @include tablet {
  top: 0px;
    overflow: visible;
  }
}
